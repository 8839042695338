.invite__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #053ffa;
  padding-top: 50px;
  padding-bottom: 64px;
}
.invite__title--pt {
  padding-top: 110px;
  padding-bottom: 0;
  margin-bottom: 24px;
}
.invite__subTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 24px;
  text-align: center;
}

.invite__submit {
  margin: 0 -16px;
  padding: 30px 24px;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  border-top: 1px solid #a1a4b1;
  a {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .paid-modal__body--submit {
    .paid-modal__action {
      margin-top: 110px;
    }
  }
}

@media (max-width: 767px) {
  .invite__title {
    padding-top: 50px;
    font-size: 24px;
    line-height: 32px;
  }
  .invite__title--pt {
    padding-top: 50px;
  }
  .invite__subTitle {
    font-size: 16px;
    line-height: 24px;
  }
}
.invite__title--pt2 {
  padding-top: 178px;
  margin-bottom: 152px;
  padding-bottom: 0;
}
.invite__btns {
  display: flex;
  justify-content: center;
  .btn {
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 15px;
    padding: 8px 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  .btn.btn-primary {
    background-color: #092c9e;
  }
}
@media (max-width: 991px) {
  .invite__title--pt2 {
    padding-top: 50px;
    max-width: 600px;
    margin: 0 auto;
  }
  .invite__btns {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .invite__btns {
    flex-wrap: wrap;
    justify-content: center;
    .btn {
      margin-right: 5px;
      margin: 0;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
