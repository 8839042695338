.proekt__content__payment {
  padding-top: 0;
  padding-left: 14px;
  padding-right: 14px;
}

.choose-pr {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 3px;
}
.choose-pr__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    margin-right: 25px;
    margin-top: 4px;
    max-width: 400px;
  }
}
.tarif {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.tarif__timer {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  text-align: center;
  margin-bottom: 14px;
}
.tarif__item {
  max-width: 335px;
  width: 100%;
  background: #d0dbff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-top: 53px;
  padding-bottom: 34px;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
  margin: 0 10px;
  margin-bottom: 24px;
}
.tarif__item.tarif__item--popular {
  background: #f4f7ff;
}
.tarif__item:not(.tarif__item--popular) {
  .btn.btn-outline-primary {
    border: 1px solid #0f47c0;
  }
}
.tarif__item.tarif__item--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.tarif__popular {
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  top: 0;
  right: 0;
  background: #a9ddff;
  border-radius: 10px;
  padding: 3px 7px;
}
.tarif__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-bottom: 10px;
  text-align: center;
  button {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  svg {
    margin-left: 3px;
    margin-bottom: 3px;
    cursor: pointer;
  }
}
.tarif__des {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
  min-height: 72px;
  margin-bottom: 4px;
  text-align: center;
}
.tarif__price {
  display: flex;
  justify-content: center;
  margin-bottom: 23px;
  align-items: center;
  margin-bottom: 20px;
}
.tarif__price__numb {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
  margin-right: 15px;
}
.tarif__price__type {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
  margin-top: 5px;
}
.tarif__views {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #2b5cfb;
  text-align: center;
  margin-bottom: 49px;
}
.tarif__views--timer {
  margin-bottom: 7px;
}
.tarif__btn {
  display: flex;
  justify-content: center;
  .btn {
    padding: 6px 49px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 767px) {
  .tarif {
    justify-content: center;
  }
  .choose-pr__item {
    flex-direction: column;
    span {
      font-size: 16px;
      line-height: 1.6;
      display: block;
      margin-bottom: 5px;
    }
    .btn {
      padding: 6px 15px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.payment__dop {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-top: 23px;
  margin-bottom: 23px;
}
.form-check.form-check--payment {
  margin-right: 24px;

  .form-check-input {
    width: 32px;
    height: 32px;
    border-radius: 0;
    border: 4px solid #9491a1;
  }
  .form-check-input:checked[type='checkbox'] {
    border: 4px solid #0d6efd;
  }
}
.payment__row {
  padding: 41px 18px;
  margin-bottom: 40px;
  background: #f4f7ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.payment__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  max-width: 100%;
  width: 339px;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  svg {
    margin-bottom: 2px;
    margin-left: 5px;
    cursor: pointer;
  }
}
.payment__count {
  flex: 1;
  padding-right: 15px;
  padding-left: 15px;
}
.payment__count__flex {
  display: flex;
  justify-content: center;
  .payment__count__numb {
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #3c64b1;
    border-top: 1px solid #dbeefb;
    border-bottom: 1px solid #dbeefb;
    position: relative;
    .new-tooltip {
      display: none;
      position: absolute;
      bottom: calc(100% + 16px);
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      background: #000000;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      color: #ffffff;
      padding: 5px;
      width: 320px;
      text-align: center;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        bottom: -6px;
        left: calc(50% - 6px);
        transform: rotate(45deg);
        background-color: #000000;
        display: block;
      }
    }
    &:hover {
      .new-tooltip {
        display: block;
      }
    }
  }

  button {
    height: 39px;
    width: 44px;
    background: #dbeefb;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    padding: 0;
    color: #3c64b1;
  }
  .minus {
    border-radius: 6px 0px 0px 6px;
  }
  .plus {
    border-radius: 0px 6px 6px 0px;
  }
}
.payment__count__text {
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}
.payment__price {
  display: flex;
  align-items: center;
  flex: 0 0 280px;
}
.payment__price__left {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}
.payment__price__right {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 3px;
  margin-left: 8px;
}
.payment__total {
  display: flex;
  justify-content: flex-end;

  margin-top: 14px;
}
.payment__total__bot {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 100px;
  flex-wrap: wrap;
  .btn {
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
}
.payment__total__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 16px;
}
.payment__total__des {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  max-width: 366px;
  margin-bottom: 10px;
  position: relative;
  .form-check--payment {
    position: absolute;
    left: -16px;
    transform: translateX(-100%);
    margin-right: 16px;
  }
}
.payment__ref {
  background: #f4f7ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 16px 24px;
  max-width: 567px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 100px;
  margin: 0 -14px;
}

.payment__ref__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  max-width: 186px;
  text-align: center;
}
.payment__ref__des {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  max-width: 177px;
  margin-bottom: 20px;
}
.payment__ref__price {
  display: flex;
  align-items: center;
  .left {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    margin-right: 5px;
  }
  .right {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 1200px) {
  .payment__price {
    flex: initial;
    width: 100%;
    margin-top: 15px;
    .payment__price__left {
      font-size: 24px;
      line-height: 1.1;
    }
    .payment__price__right {
      font-size: 14px;
      line-height: 1.1;
      margin-left: 5px;
    }
  }
  .payment__name {
    font-size: 16px;
    line-height: 22px;
    width: 205px;
  }
  .payment__row {
    align-items: flex-start;
    margin-bottom: 20px;
    .form-check.form-check--payment {
      margin-right: 10px;
    }
  }
  .payment__total {
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .proekt__content__payment {
    padding-right: 0;
    padding-left: 0;
  }
  .payment__ref {
    margin: 0;
  }
}
@media (max-width: 575px) {
  .payment__count {
    flex: auto;
    width: 100%;
    margin: 15px 0;
  }
  .payment__name {
    flex: 1;
    width: auto;
  }
  .payment__row {
    padding: 25px 15px;
  }
  .payment__price {
    justify-content: center;
  }
  .payment__total__title {
    font-size: 24px;
    line-height: 30px;
  }
  .payment__total__des {
    font-size: 16px;
    line-height: 24px;
  }
  .payment__total__bot {
    justify-content: center;
  }
  .payment__total__des {
    display: flex;
  }
  .payment__total__des .form-check--payment {
    position: initial;
    transform: translate(0);
  }
  .payment__total {
    justify-content: center;
  }
  .payment__total__title {
    text-align: center;
  }
  .payment__ref {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 25px;
  }
  .payment__ref__title {
    max-width: initial;
    margin-bottom: 5px;
  }
  .payment__ref__des {
    margin-right: 0;
    max-width: initial;
    margin-bottom: 10px;
  }
  .payment__ref__price {
    justify-content: center;
  }
  .payment__ref__price {
    margin-bottom: 15px;
  }
}

.paid-modal {
  width: 1356px;
  max-width: 100%;
  position: relative;
}
.paid-modal__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  text-align: center;

  /* Colors/Primary/Blue/500 */

  color: #053ffa;
  padding-top: 49px;
  padding-bottom: 64px;
}
.paid-modal__body {
  border-top: 1px solid #a1a4b1;
  border-bottom: 1px solid #a1a4b1;
  margin: 0 -16px;
  padding-top: 42px;
  padding-right: 36px;
  padding-left: 36px;
  padding-bottom: 125px;
  margin-bottom: 49px;
}
.paid-modal__checkbox {
  display: flex;
  margin-bottom: 14px;
  cursor: pointer;
  input {
    position: absolute;
    z-index: -99;
    opacity: 0;
  }
  input + .paid-modal__checkbox__check {
    border: 3px solid #a1a4b1;
    border-radius: 3px;
    width: 18px;
    min-width: 18px;
    height: 18px;
    position: relative;
    margin-right: 11px;
    svg {
      position: absolute;
      top: -2px;
      left: 1px;
      opacity: 0;
    }
  }
  input:checked + .paid-modal__checkbox__check {
    border: 3px solid #18171c;
    svg {
      opacity: 1;
    }
  }
  input + .paid-modal__checkbox__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #505565;
  }
  input:checked + .paid-modal__checkbox__text {
    color: #0a243f;
  }
}
.paid-modal__action {
  display: flex;
  align-items: flex-start;
  margin-top: 165px;
  justify-content: center;
  .btn {
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 15px;
    padding: 8px 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  .btn.btn-primary {
    background-color: #092c9e;
  }
}

@media (max-width: 991px) {
  .paid-modal__action {
    padding-right: 0;
    margin-top: 50px;
    justify-content: flex-start;
  }
  .paid-modal__body {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .paid-modal__action {
    flex-wrap: wrap;
    justify-content: center;
    .btn {
      margin-right: 5px;
      margin: 0;
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .paid-modal__body {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 15px;
  }
  .paid-modal__title {
    font-size: 20px;
    line-height: 28px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.p-balance {
  border-bottom: 1px solid #a1a4b1;
  margin: 0 - 14px;
  padding-top: 27px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.8);
  padding-bottom: 14px;
  margin-bottom: 20px;
}
.balance__total {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0f47c0;
  margin-left: 37px;
}
.p-balance__info {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .p-balance {
    margin-right: 0;
    margin-left: 0;
  }
}

.complited {
  background: #f4f7ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  padding: 29px 18px;
  padding-right: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.complited__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  max-width: 339px;
  margin-right: 15px;
}
.complited__name2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  max-width: 212px;
  text-align: right;
}
.complited__top {
  margin-bottom: 56px;
}
.complited__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 31px;
  margin-top: 40px;
}
.complited__page {
  padding-bottom: 62px;
}

@media (max-width: 767px) {
  .complited__top {
    margin-bottom: 25px;
  }
  .complited__title {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .complited {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .complited__name {
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 22px;
  }
}
.proekt__tabs.proekt__tabs--payment li {
  margin-right: 34px;
  &:last-child {
    margin-right: 0;
  }
}

.payHistory {
  margin: 0 -14px;
}
.payHistory__item {
  background: #f4f7ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin-bottom: 22px;
  padding: 12px 31px;
  .data {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 7px;
  }
  .payHistory__row {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    .left {
      min-width: 155px;
      margin-right: 35px;
    }
  }
  .success {
    color: #26d983;
  }
  .pending {
    color: #1d83e2;
  }
  .notcomplied {
    color: #f2a60d;
  }
  .error {
    color: #e54934;
  }
}
@media (max-width: 767px) {
  .payHistory__item .payHistory__row {
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
  }
  .payHistory {
    margin: 0;
  }
  .payHistory__item {
    padding: 15px;
    .data {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
.payment__name__position {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.paymentStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.paymentStatus__text {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-top: 23px;
  margin-bottom: 15px;
}
.payment__err {
  font-weight: bold;
  margin-bottom: 15px;
  text-align: right;
  width: 100%;
  margin-top: 15px;
  color: red;
}
.payment-disable {
  opacity: 0.5;
  pointer-events: none;
}
.payment__ref--sidebar {
  max-width: 229px;
  .payment__ref {
    margin: 0;
    width: 100%;

    flex-direction: column;
    align-items: center;
    margin-top: 28px;
    padding: 16px 18px;
    padding-bottom: 30px;
    .payment__ref__title {
      margin-bottom: 9px;
    }
    .payment__ref__des {
      margin-right: 0;
    }
    .payment__ref__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 14px;
    }
  }
  .btn {
    padding: 0.45em 1em;
  }
}
@media (max-width: 991px) {
  .payment__ref--sidebar {
    .payment__ref__title {
      font-size: 16px;
      line-height: 24px;
    }
    .payment__ref__des {
      font-size: 14px;
      line-height: 16px;
    }
    .btn {
      font-size: 14px;
      line-height: 22px;
      padding: 0.45rem 1rem;
    }
    .payment__ref {
      padding: 14px 10px;
      padding-bottom: 15px;
    }
  }
}
@media (max-width: 767px) {
  .payment__ref--sidebar {
    margin: 0 auto;
  }
}
