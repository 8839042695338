.project-current__item {
  padding-top: 47px;
  padding-bottom: 21px;
  border-bottom: 1px solid #a1a4b1;
}
.current-project {
  padding-bottom: 50px;
}
.project-current__bot {
  display: flex;
  justify-content: flex-end;
  .proekt__chat {
    background-color: transparent;
    padding: 0;
    border: 0;
    margin-bottom: 0;
    svg {
      margin-left: 11px;
      transition: 0.3s all;
    }
    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
}
.project-current__bot.project-current__bot--detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.proekt__detail__status {
  display: flex;
  width: 100%;
  padding-bottom: 19px;
  overflow-x: auto;
}
.proekt__detail__status__item {
  padding: 2px 16px;
  padding-right: 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  color: #9491a1;
  background: #f2f1f3;
  position: relative;
  min-width: max-content;
  span {
    position: relative;
    z-index: 3;
  }
  &:first-child {
    padding-left: 7px;
  }
  &:after {
    content: '';
    width: 18px;
    height: 18px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    top: 3px;
    right: -9px;
    transform: rotate(-45deg);
    background: #f2f1f3;
    z-index: 2;
    pointer-events: none;
  }
  &.active {
    color: #f1f6fa;
    background: #2b5cfb;
  }
  &.active:after {
    background: #2b5cfb;
  }
  &:last-child:after {
    display: none;
  }
}
.project-current__item.detail {
  padding-top: 0;
  .proekt__content__text {
    margin-bottom: 8px;
  }
}
.project-current__item {
  .proekt__content__person {
    flex-wrap: wrap;
  }
  .proekt__content__avatar {
    margin-bottom: 15px;
  }
  .proekt__content__person__name {
    max-width: 300px;
  }
}
@media (min-width: 1350px) {
  .project-current__item.detail {
    .proekt__content__top,
    .project-current__contnent {
      padding-left: 33px;
      padding-right: 33px;
    }
  }
}
.project-current__head {
  padding-top: 40px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-left: 33px;
  padding-right: 33px;
  border-bottom: 1px solid #e0e4ef;
  margin-bottom: 21px;
}
.project-current__head.greeting {
  padding-top: 10px;
}
.project-current__greeting {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  padding: 3px 5px;
  background: #f4f7ff;
  margin-bottom: 5px;
}
.project-current__head__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  flex-wrap: wrap;
  a {
    margin-bottom: 15px;
  }
}
.project-current__head__title {
  max-width: 600px;
  padding-right: 15px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
}
.project-current__stavka {
  padding-top: 23px;
  margin-top: 45px;
  margin-bottom: 32px;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 920px;
    border-bottom: 1px solid #e0e4ef;
  }
}
.project-current__stavka__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
}
.project-current__actions {
  margin-bottom: 43px;
  button:first-child {
    margin-right: 68px;
  }
  .btn--suport {
    padding: 0.45rem 1rem;
  }
}
@media (min-width: 767px) {
  .project-current__actions.status5 {
    justify-content: flex-end;
    max-width: initial;
    flex-direction: row;
    display: flex;
    button {
      margin-right: 0;
      padding: 0;
    }
  }
}

@media (max-width: 1349px) {
  .project-current__head {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width: 1300px) {
  .project-current__item {
    .proekt__content__top {
      flex-direction: column;
    }
    .proekt__content__right {
      flex: none;
    }
    .proekt__content__left {
      padding-right: 0;
      flex: none;
    }
  }
}
@media (max-width: 991px) {
  .project-current__head__title {
    font-size: 24px;
    line-height: 32px;
  }
  .project-current__greeting {
    font-size: 16px;
    line-height: 22px;
  }
  .project-current__stavka {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .project-current__actions button:first-child {
    margin-right: 0;
  }
  .project-current__actions {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    button {
      margin-bottom: 15px;
    }
  }
  .project-current__item {
    padding-top: 25px;
  }
}
@media (max-width: 767px) {
  .project-current__actions {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .project-current__bot.project-current__bot--detail {
    flex-direction: column-reverse;
    align-items: center;
    .proekt__chat {
      margin-left: auto;
      margin-top: 25px;
    }
  }
}
.project-current__card {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;
  letter-spacing: -0.015em;

  color: #000000;
  padding: 14px 20px;
  background: #f4f7ff;
  margin-bottom: 27px;
}
.choose__expert-modal__card {
  display: flex;
  justify-content: center;
}
.choose__expert-modal__card__col {
  margin: 0 40px;
  input {
    border: 0;
    width: 180px;
    border-bottom: 1px solid #000;
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
  }
}
.choose__expert-modal__card__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 2px;
}
.choose__expert-modal__card__name {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 767px) {
  .project-current__card {
    font-size: 14px;
    line-height: 22px;
  }
  .project-current__actions button {
    font-size: 14px;
  }
  .project-current__bot button {
    font-size: 14px;
  }
}
.choose__expert-modal__info__btn {
  background-color: transparent;
  padding: 0;
  border: 0;
  color: var(--color-blue);
  font-weight: bold;
}

.choose__expert-modal__info__btn.red {
  color: var(--color-red);
}
.choose__expert-modal__info .checkbox {
  max-width: 452px;
  margin: 0 auto;
  margin-top: 25px;
  font-size: 12px;
  line-height: 1.3;
  label {
    transform: translate(0);
  }
}
.custom-modal-big--card {
  min-height: initial;
  .choose__expert-modal__bot {
    padding-top: 50px;
    padding-bottom: 50px;
    border-bottom: 0;
  }
}

@media (max-width: 991px) {
  .choose__expert-modal__card {
    flex-direction: column;
  }
  .choose__expert-modal__card__col {
    margin: 0;
    margin-bottom: 25px;
  }
  .choose__expert-modal__info .checkbox {
    margin-top: 0;
  }
  .custom-modal-big--card .choose__expert-modal__bot {
    flex-wrap: wrap;
    padding-top: 25px;
    padding-bottom: 25px;
    .btn {
      margin-bottom: 15px;
    }
  }
}
