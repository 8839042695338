.proekt {
  margin-top: 44px;
  display: flex;
}
.selectize-input.focus > input {
  width: 100% !important;
}
.filter__title {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 39px;
}

.filter__tabs {
  display: flex;
}
.filter__tab {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #828282;
  margin-right: 42px;
  margin-bottom: 32px;
}

.filter__tab:last-child {
  margin-right: 0;
}
.filter__tab.active,
.filter__tab:hover {
  color: #000000;
}

@media (min-width: 1450px) {
  .proekt {
    margin-left: -32px;
  }
}

.filter__label {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Mulish';
  margin-bottom: 8px;
  letter-spacing: 0.1px;
  display: block;
  color: #737b7d;
  margin-bottom: 13px;
}

.filter {
  flex: 0 0 322px;
  margin-right: 54px;
  .form-select {
    background-size: 10px;
    background-position: right 1rem center;
  }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}

.input-search {
  position: relative;
  input {
    width: 100%;
    height: 48px;
    padding-left: 50px;
    margin-bottom: 0;
  }
  button {
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    position: absolute;
    background-color: transparent;
    border: 0;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    padding: 0;
  }
  button:hover {
    background-color: #f1f6fa;
  }
}

.filter__row {
  margin-bottom: 32px;
  .react-select .react-select__control {
    height: 48px;
  }
  .react-select.zero .react-select__single-value {
    color: #79758a;
  }
}
.filter__checkboxes {
  margin-bottom: 30px;
  .form-check-input {
    width: 1.3em;
    height: 1.3em;
    border: 2.5px solid #a1a4b1;
    border-radius: 3px;
  }
  .form-check-input:checked {
    border: 2px solid #0d6efd;
  }
  .form-check-label {
    font-size: 14px;
    line-height: 24px;
    color: #505565;
    padding-left: 8px;
    padding-top: 1px;
  }
  .form-check {
    margin-bottom: 10px;
  }
}

.filter__links {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  padding-top: 15px;
  .btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #f4f7ff;
    background: #092c9e;
    font-family: 'Montserrat';

    &:hover {
      color: #fff;
      background: #0f47c0;
    }
  }
}
.filter__links li {
  list-style: none;
  margin-bottom: 24px;
}
.filter__links a,
.filter__links button {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #737b7d;
  font-weight: bold;
  transition: 0.3s all;
  font-family: 'Mulish';
  background-color: transparent;
  border: 0;
}

.filter__links a,
.filter__links button:hover {
  color: #000;
}

.proekt__right {
  flex: 1;
}

.proekt__detail {
  background-color: #fff;
  border-radius: 15px;
}

.proekt__search {
  max-width: 633px;
}

.search__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}

.search__tag {
  padding: 4px 10px;
  background: #dbeefb;
  border-radius: 40px;
  font-weight: bold;
  font-size: 10px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  color: #3c64b1;
  margin-right: 7px;
  margin-bottom: 10px;
  position: relative;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;
  }
}

.proekt__search {
  margin-bottom: 32px;
}

.proekt__name {
  padding: 41px 33px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e4ef;
}
.proekt__actios {
  margin-left: 15px;
  display: flex;
  position: relative;
  svg {
    min-width: 13.2px;
    min-height: 13.2px;
  }
  .active-svg {
    display: none;
  }
}

.selectize-input.dropdown-active::before {
  display: none;
}

.proekt__actios button {
  background: #ffffff;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  color: #000;
}
.hot-project {
  margin-right: 10px;
}

.proekt__actios button:hover,
.proekt__actios button.active {
  background-color: #f1f6fa;
  color: var(--color-blue);
}

.proekt__actios button.active {
  svg {
    display: none;
  }
  .active-svg {
    display: block;
  }
}

.proekt__content {
  padding-top: 24px;
  padding-right: 33px;
  padding-left: 33px;
}
.proekt__content__top {
  display: flex;
}
.proekt__content__left {
  flex: 1;
  padding-right: 15px;
}
.proekt__content__status {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 4px;
}
.selectize-control.single .selectize-input {
  width: 100%;
  height: 48px;

  margin-bottom: 0;
  padding: 0 14px;
  border: 1px solid #d7d6dc;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../../assets/img/proekt/search.svg');
  background-size: 17.5px;
  background-position: left 19px center;
  background-repeat: no-repeat;
  padding-left: 50px;
}

.selectize-control.single .selectize-input.dropdown-active {
  border-radius: 20px 20px 0 0;
  border: 2px solid #2b5cfb;
}
.selectize-input,
.selectize-control.single .selectize-input.input-active {
  display: flex;
  background-color: #fff;
}
.selectize-input > input {
  font-size: 15px;
  line-height: 160%;
}
.selectize-input > input::placeholder {
  color: #afacb9;
}
.selectize-input .item {
  font-size: 15px;
  line-height: 160%;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

// .selectize-control.single .selectize-input.dropdown-active .item {
//     display: none
// }

.selectize-control.single .selectize-input:after {
  border-color: #a1a4b1 transparent transparent transparent;
}

.selectize-dropdown {
  background: #fff;
  border: 1px solid #d7d6dc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;
}

.selectize-control {
  height: 48px;
}
.selectize-control.single .selectize-input:after {
  display: none;
}
.selectize-dropdown [data-selectable] {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.6);
}

.selectize-dropdown [data-selectable] .highlight {
  color: #000;
  background-color: transparent;
}
.selectize-search {
  position: relative;
}

.selectize-search--another-border {
  .selectize-control.single .selectize-input {
    border-radius: 10px;
  }
  .selectize-control.single .selectize-input.dropdown-active {
    border-radius: 10px 10px 0 0;
  }
}
.custom-select--another-border {
  .select-selected {
    border-radius: 10px;
    border: 1px solid #d7d6dc;
    box-shadow: 0 1px 3px rgb(0, 0, 0, 15%);
  }
  .select-selected.select-arrow-active {
    border-radius: 10px 10px 0px 0px;
  }
}

.proekt__content__avatar {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  margin-right: 12px;
  z-index: 2;
  cursor: pointer;
}
.proekt__pro-expert {
  position: relative;
  .pro-label {
    position: absolute;
    top: 25px;
    right: 0;
    z-index: 2;
  }
}
.proekt__content__person {
  display: flex;
  align-items: flex-start;

  margin-bottom: 9px;
}
.proekt__content__person__name {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-right: 23px;

  svg {
    margin-left: 15px;
    margin-bottom: 5px;
  }
}
a.proekt__content__person__name:hover {
  color: #000000;
  text-decoration: underline;
}
.proekt__right--detail {
  .proekt__content__person {
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 9px;
    .proekt__location {
      margin-top: 15px;
    }
  }
  .proekt__content__person__name {
    margin-top: 15px;
  }
}
.proekt__tags {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.proekt__tags__item {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 5px;
}
.proekt__tags__item--premium {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #0f47c0;
  display: flex;
  align-items: center;
  margin-right: 13px;
  margin-top: -5px;
  svg {
    margin-right: 6px;
  }
}
.tags-point {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}
.tags-point:before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  min-width: 6px;
  border-radius: 100%;
  background: #c4c4c4;
  margin-left: 0;
  margin-right: 5px;
}
.proekt__content__right {
  flex: 0 0 255px;
}

.proekt__stat__status {
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #a3a3a3;
  margin-bottom: 6px;
  letter-spacing: 0.3px;
}
.proekt__stat__item {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #373f41;
  letter-spacing: 0.2px;
  margin-bottom: 8px;
  img,
  svg {
    margin-right: 11px;
  }
}
.proekt__content__text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373f41;
  margin-bottom: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
.proekt__right--detail {
  .proekt__content__text {
    display: block;
  }
}
.proekt__content__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 10px;
}

.proekt__content__tags__item {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #3c64b1;
  margin-right: 10px;
  padding: 8px 16px;
  background: #dbeefb;
  border-radius: 20px;
  margin-bottom: 9px;
}
.proekt__content__bot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.proekt__location {
  display: flex;
  align-items: flex-end;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
  padding-right: 15px;
  svg {
    margin-right: 13px;
  }
}

.proekt__content__actions {
  flex: 0 0 255px;
  .btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #f4f7ff;
    padding: 8px 20px;
  }
}
.proekt__chat {
  display: flex;
  align-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #053ffa;
  margin-bottom: 20px;
  transition: 0.3s all;
  svg {
    margin-right: 4px;
  }
}
.proekt__detail__showMore {
  display: flex;
  align-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #053ffa;
  margin-bottom: 20px;
  transition: 0.3s all;
  background-color: transparent;
  border: 0;
  svg {
    margin-left: 4px;
    transition: 0.3s all;
  }
  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
}
.proekt__chat:hover {
  opacity: 0.9;
}
.proekt__detail__showLess {
  display: flex;
  align-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #053ffa;
  margin-bottom: 20px;
  transition: 0.3s all;
  background-color: transparent;
  border: 0;
  svg {
    margin-right: 4px;
    transition: 0.3s all;
    transform: rotate(180deg);
  }
  &:hover {
    svg {
      transform: rotate(180deg) translateX(5px);
    }
  }
}
.proekt__more {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 75px;
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
}

@media (max-width: 1300px) {
  .filter {
    margin-right: 25px;
  }
  .proekt__search {
    margin-bottom: 15px;
  }
  .proekt__more {
    margin-top: 25px;
    padding-bottom: 50px;
  }
  .filter__checkboxes {
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .proekt__content__top {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .filter__content {
    display: none;
  }
  .proekt {
    flex-direction: column;
  }
  .filter {
    flex: auto;
    width: 100%;
  }
  .filter__title {
    position: relative;
    display: inline-block;
    padding-right: 35px;
    margin-bottom: 15px;
  }

  .filter__title:after {
    position: absolute;
    content: '';
    top: 15px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #000 transparent transparent transparent;
  }
  .filter__title.active:after {
    border-color: transparent transparent #000 transparent;
    top: 12px;
  }
  .filter__tab {
    margin-bottom: 20px;
  }
  .filter__row {
    margin-bottom: 20px;
  }
  .filter__label {
    margin-bottom: 10px;
  }
  .proekt__tags__item {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .proekt__name {
    padding: 20px 20px;
  }
  .proekt__content {
    padding-right: 20px;
    padding-left: 20px;
  }
  .proekt__chat {
    margin-bottom: 15px;
  }
  .proekt__content__right {
    flex: 0 0 200px;
  }
  .proekt__content__text {
    margin-bottom: 25px;
  }
  .proekt__name {
    font-size: 24px;
    line-height: 1.3;
  }
  .proekt__tags__item {
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .proekt__content__person__name {
    font-size: 14px;
  }

  .proekt__content__tags__item {
    padding: 6px 12px;
  }
}

@media (max-width: 575px) {
  .proekt__more .btn {
    font-size: 14px;
    line-height: 1.4;
    padding: 8px 20px;
  }
  .proekt__content__actions .btn {
    font-size: 14px;
    line-height: 1.4;
  }

  .proekt__content__bot {
    flex-direction: column;
    align-items: flex-start;
  }
  .proekt__name {
    flex-direction: column;
    align-items: flex-start;
  }
  .proekt__actios {
    margin-left: 0;
    margin-top: 15px;
  }
  .proekt__actios button {
    margin-left: 0;
    margin-right: 10px;
  }
  .proekt__name {
    font-size: 18px;
  }
  .proekt__tags__item {
    font-size: 14px;
    line-height: 20px;
  }
  .proekt__stat__status {
    font-size: 14px;
    line-height: 1.3;
  }
  .proekt__content__right {
    flex: auto;
  }
  .proekt__content__left {
    padding-right: 0;
  }
  .proekt__location {
  }
  .proekt__content__actions {
    flex: auto;
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .proekt__more {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .proekt__content__tags__item {
    font-size: 12px;
    line-height: 1.3;
  }
  .proekt__stat__item {
    font-size: 12px;
    line-height: 1.3;
  }
  .proekt__stat__item img {
    margin-right: 5px;
  }
}

@media (max-width: 374px) {
  .proekt__more .btn {
    font-size: 13px;
  }
  .proekt__content__actions .btn {
    font-size: 13px;
  }
}

.proekt__list {
  background-color: #fff;
  border-radius: 15px;
  padding-right: 32px;
  padding-left: 32px;
}
.proekt__list__top {
  padding-top: 37px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
  padding-bottom: 10px;
}
.proekt__list {
  .proekt__content__person--list {
    .proekt__tags {
      margin-bottom: 0;
    }
    .proekt__content__person__name {
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      color: #0a243f;
      margin-bottom: 8px;
      display: inline-block;
    }
    a.proekt__content__person__name:hover {
      text-decoration: underline;
    }
  }
  .proekt__content__right {
    flex: none;
  }
  .proekt__content__avatar {
    margin-bottom: 8px;
  }
  .proekt__content__text {
    max-width: 745px;
    margin-bottom: 15px;
  }
  .proekt__content {
    padding: 0;
  }
  .proekt__chat {
    margin-bottom: 40px;
    svg {
      margin-left: 11px;
      transition: 0.3s all;
    }
  }
  .proekt__chat:hover {
    svg {
      transform: translateX(5px);
    }
  }
  .proekt__content__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .proekt__more {
    max-width: initial;
    padding-bottom: 50px;
    margin: 0;
  }
  .proekt__content__tags {
    max-width: 700px;
  }
}

@media (min-width: 1300px) {
  .proekt__list {
    .proekt__content__actions {
      margin-top: -45px;
    }
  }
}
.proekt__list__item {
  padding-top: 52px;
  padding-bottom: 40px;
  padding-right: 20px;
  border-top: 1px solid #e0e4ef;
}
.proekt__list__item--hot {
  background: #fffae2;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 75px;
    top: 0;
    height: 100%;
    background: #fffae2;
    display: block;
    z-index: 1;
    transform: translateX(-100%);
  }
}
@media (max-width: 1349px) {
  .proekt__list__item--hot {
    &:before {
      display: none;
    }
  }
  .proekt__list__item {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .proekt__list__item {
    padding-right: 0px;
    padding-left: 0px;
  }
  .proekt__list__item {
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    width: auto;
  }
}
@media (max-width: 1300px) {
  .proekt__list {
    .proekt__content__person {
      align-items: flex-start;
    }
  }
}

@media (max-width: 767px) {
  .proekt__list {
    padding-right: 20px;
    padding-left: 20px;
    .proekt__list__item {
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .proekt__chat {
      margin-bottom: 20px;
    }
    .proekt__list__top {
      padding-top: 20px;
    }
  }
}

@media (max-width: 575px) {
  .proekt__list {
    .proekt__content__person--list .proekt__content__person__name {
      font-size: 14px;
      line-height: 1.4;
      min-height: 50px;
    }
    .proekt__content__person--list .proekt__tags {
      margin-left: -60px;
      margin-top: 20px;
    }
  }
  .proekt__list__top {
    font-size: 28px;
  }
}

.exp-content__blocks {
  flex: 1;
  width: calc(100% - 310px);
  .exp-content__edit {
    width: 100%;
    margin-bottom: 64px;
    min-height: 515px;
  }
}
.proekt__tabs {
  margin-top: 42px;
  border-bottom: 1px solid #a1a4b1;
  margin-bottom: 29px;
  ul {
    display: flex;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 2px;
  }
  li {
    list-style: none;
    margin-right: 58px;
    margin-bottom: -2px;
    min-width: max-content;
  }
  a,
  button {
    padding: 0;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    display: block;
    color: #0a243f;
    background-color: transparent;
    border: 0;
  }
  a:hover,
  button:hover {
    color: #2b5cfb;
  }
  a.active,
  button.active {
    color: #2b5cfb;
    border-bottom: 2px solid #2b5cfb;
  }
}

.my-proekts-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #a1a4b1;
  margin-bottom: 25px;
}

.my-proekts-filter__search {
  position: relative;
  width: 320px;
  margin-bottom: 41px;
  margin-right: 15px;
  input {
    background: #ffffff;
    border-radius: 20px;
    height: 48px;
    padding-left: 40px;
    padding-right: 15px;
    font-size: 14px;
    line-height: 20px;
    border: 0;
    width: 100%;
    border: 1px solid #a1a4b1;
    padding-right: 40px;
  }
  button {
    position: absolute;
    left: 6px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100%;
  }
  button.my-proekts-filter__clear {
    left: auto;
    top: 5px;
    right: 5px;
    transform: translate(0);
  }
}
.my-proekts-filter__wrap {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.my-proekts-filter__item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #0a243f;
  font-family: 'Mulish';
  margin-bottom: 41px;
  margin-right: 50px;
  white-space: nowrap;
  .select-selected {
    padding: 0;
    border: 0;
    border-radius: 0;
    margin-left: 5px;
    padding-right: 16px;
    font-size: 18px;
    line-height: 24px;
    color: #0a243f;
    letter-spacing: 0.1px;
    font-weight: 400;
    font-family: 'Mulish';
  }
  .select-selected:after {
    top: 10px;
    right: 0;
  }
  .select-selected.select-arrow-active:after {
    top: 4px;
  }
  .select-selected.select-arrow-active {
    box-shadow: none;
  }
  .select-items {
    width: 175px;
    right: auto;
  }
  .form-select {
    padding: 0;
    border: 0;
    background-color: transparent;
    padding-right: 30px;
    padding-left: 5px;
    font-size: 18px;
    line-height: 24px;
    color: #0a243f;
  }
  .form-select.selected {
    padding-right: 3px;
    background-image: none;
  }
}

.my-proekts-filter__item:last-child {
  .select-items {
    width: 175px;
    left: auto;
    right: 0;
  }
}

.my-proekts-list__item {
  margin-bottom: 36px;
  padding-bottom: 20px;
  border-bottom: 1px solid #a1a4b1;

  .data {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.3px;
    color: #a3a3a3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Mulish';
    span {
      margin-left: 26px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #0a243f;
    margin-bottom: 10px;
    display: block;
  }
  .title:hover {
    text-decoration: underline;
  }
  .text {
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 19px;
  }
  .detail {
    display: flex;
    justify-content: flex-end;
    a {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 28px;
      color: #2b5cfb;
      svg {
        margin-left: 11px;
        transition: 0.3s all;
      }
    }
    a:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
}
.my-proekts-list__item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.now-proekt__empty {
  text-align: center;
  max-width: 692px;
  margin: 0 auto;
}
.my-proekts-list.proekt__list {
  padding: 0;
  margin: 0;
  .proekt__content__actions {
    margin-top: 0;
    .proekt__chat {
      margin-bottom: 0;
      padding: 0;
      background-color: transparent;
      border: 0;
    }
  }
  .proekt__list__item {
    border-top: 0;
    padding-top: 0;
    border-bottom: 1px solid #e0e4ef;
    margin-bottom: 52px;
  }
  .proekt__list__item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
  .proekt__content__tags {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .proekt__tabs {
    margin-top: 30px;
  }
  .proekt__tabs li {
    margin-right: 25px;
  }
  .proekt__tabs a,
  .proekt__tabs button {
    font-size: 18px;
    line-height: 1.4;
  }
  .my-proekts-filter__item {
    margin-bottom: 25px;
  }
  .my-proekts-filter .selectize-search {
    margin-bottom: 25px;
  }
  .my-proekts-filter__search {
    margin-bottom: 25px;
  }
  .my-proekts-filter__item .select-selected {
    margin-left: 0;
  }
  .my-proekts-list__item {
    margin-bottom: 25px;
    .data {
      margin-bottom: 5px;
    }
  }
  .exp-content__blocks .exp-content__edit {
    min-height: 315px;
    margin-bottom: 20px;
  }
  .my-proekts-list.proekt__list .proekt__list__item {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .exp-content__blocks {
    width: 100%;
    flex: auto;
  }
}

@media (max-width: 575px) {
  .my-proekts-list__item .title {
    font-size: 18px;
    line-height: 1.4;
  }
  .my-proekts-list__item .data {
    margin-bottom: 10px;
  }
  .my-proekts-list__item .text {
    font-size: 14px;
  }
  .my-proekts-filter__wrap {
    flex-direction: column;
  }
  .my-proekts-filter__item {
    width: 100%;
    margin-right: 0;
    font-size: 16px;
    line-height: 20px;
  }
  .my-proekts-list.proekt__list .proekt__content__actions .proekt__chat {
    margin-top: 15px;
  }
  .proekt__tabs a,
  .proekt__tabs button {
    font-size: 16px;
  }
}

.proekt-modal {
  width: 1356px;
  max-width: 100%;
  position: relative;
  padding-top: 45px;
}
.proekt-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
}
.proekt-modal__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #053ffa;
  text-align: center;
  margin-bottom: 24px;
}

.proekt-modal__titleSub {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  text-align: center;
  margin-bottom: 70px;
}

.proekt-modal__row {
  border-top: 1px solid #a1a4b1;
  border-bottom: 1px solid #a1a4b1;
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-right: -16px;
  margin-left: -16px;
  margin-bottom: 157px;
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1d83e2;
  }
}
.proekt-modal__bot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 188px;
  .btn {
    margin: 15px 12px;
  }
}

@media (max-width: 767px) {
  .proekt-modal__row {
    margin-bottom: 50px;
  }
  .proekt-modal__bot {
    margin-bottom: 50px;
  }
}

.proekt-detail__level1 {
  .item {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #053ffa;
    margin-bottom: 8px;
  }
}
.proekt-detail__level2 {
  display: flex;
  flex-wrap: wrap;
  .item {
    color: #000000;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 4px 10px;
    background: #dbeefb;
    border-radius: 15px;
    margin-right: 18px;
    margin-bottom: 14px;
  }
}
.proekt-detail__lang {
  display: flex;
  flex-wrap: wrap;
  .item {
    color: #3c64b1;
    font-family: 'Mulish';

    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    padding: 6px 8px;
    background: #dbeefb;
    border-radius: 15px;
    margin-right: 14px;
    margin-bottom: 14px;
  }
}
.proekt__right--detail {
  .proekt__content__text {
    margin-bottom: 12px;
  }

  .proekt__more {
    justify-content: flex-end;
    .btn {
      margin-left: 24px;
    }
  }
}
.new-check {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Mulish';
  }
}
.proekt-detail__duration {
  margin-top: 5px;
  .duration1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }
}

.proekt-detail__bot {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .proekt__right--detail {
    .proekt__more {
      justify-content: center;
      flex-direction: column;
      .btn {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }
  .proekt-detail__bot {
    justify-content: center;
    margin-top: 25px;
  }
  .proekt__content__person .proekt__location {
    width: 100%;
  }
}

.proekt__content__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
  margin-bottom: 8px;
}
.proekt__detail__top {
  padding-bottom: 33px;
  border-bottom: 1px solid #e0e4ef;
  padding-top: 12px;
  padding-right: 12px;
  padding-left: 12px;
}
.proekt__detail--new {
  .proekt__content {
    padding-top: 16px;
  }
  .proekt__more {
    margin: 0;
    max-width: initial;
    justify-content: flex-start;
    padding-top: 24px;
    padding-bottom: 60px;
    .btn {
      margin: 0;
      margin-right: 24px;
    }
  }
}
.proekt__moreInfo {
  padding-top: 13px;
  padding-bottom: 24px;
  border-bottom: 1px solid #d7d6dc;
}
.proekt__moreInfo__btn {
  background-color: transparent;
  border: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #0f47c0;
  svg {
    margin-left: 2px;
    transition: 0.3s all;
  }
  &:hover {
    text-decoration: underline;
    svg {
      transform: translateX(2px);
    }
  }
}
.proekt__body {
  padding-top: 46px;
  margin-top: 46px;
  position: relative;
  &:after {
    content: '';
    border-top: 4px solid #d7d6dc;
    position: absolute;
    top: 0;
    left: 65px;
    width: 900px;
    max-width: 100%;
  }
}
.proekt__body__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #0a243f;
  margin-bottom: 24px;
}
.proekt__body__text {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-bottom: 18px;
}
.proekt__body__stavka {
  display: flex;
  padding-bottom: 24px;
  border-bottom: 1px solid #d7d6dc;
  margin-bottom: 24px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .left {
    flex: 0 0 450px;
    margin-right: 25px;
  }
  .form-check {
    display: inline-flex;
    align-items: center;
    padding: 0;
    button {
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .form-check-input {
      width: 18px;
      height: 18px;
      border: 2px solid rgba(0, 0, 0, 0.25);
      margin: 0;
      margin-right: 11px;
    }
    .form-check-label {
      margin-right: 10px;
    }
  }
}
.bold-text {
  font-weight: 700;
  margin-bottom: 2px;
}
.stavla__input {
  display: flex;
  align-items: center;
  position: relative;
  .input {
    width: 213px;
    margin-right: 6px;
    height: 38px;
    font-weight: bold;
    padding-right: 45px;
    margin-bottom: 0;
  }
  .stavka__label {
    position: absolute;
    right: 80px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
    font-weight: bold;
  }
  .stavka__for__proekt {
    font-weight: bold;
  }
}
.proekt__body__stavka__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 755px;
  .row__left {
    flex: 0 0 450px;
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .row__right {
    flex: 1;
    font-weight: 700;
    color: #9491a1;
    display: flex;
    justify-content: space-between;
  }
  .row-arr {
    display: flex;
    flex-direction: column;
    align-items: center;
    .row-arr__item {
      min-width: 35px;
      position: relative;
      text-align: center;
      span {
        position: absolute;
        top: 50%;
        right: -2px;
        transform: translate(100%, -50%);
      }
    }
    svg {
      margin-left: 5px;
    }
  }
}
.row__right__for__pr {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    margin-right: 5px;
  }
}
.stavka__textarea {
  width: 100%;
  textarea {
    border: 1px solid #d7d6dc;
    border-radius: 12px;
    height: 109px;
    display: block;
    width: 100%;
    padding: 8px;
    &.error {
      border: 1px solid #e54934;
    }
  }
  .invalid-feedback {
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #e54934;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1280px) {
  .proekt__body__stavka .left {
    flex: 0 0 300px;
  }
  .proekt__body__stavka__row .row__left {
    flex: 0 0 300px;
  }
  .proekt__body__stavka__row {
    max-width: 655px;
  }
  .proekt__body:after {
    left: 0;
    width: 100%;
  }
}
@media (max-width: 1100px) {
  .stavla__input .input {
    width: 145px;
  }
}
@media (max-width: 991px) {
  .stavla__input .input {
    width: 213px;
  }
  .proekt__body__stavka__row {
    max-width: 605px;
  }
}
@media (max-width: 767px) {
  .proekt__body__stavka {
    flex-direction: column;
  }
  .proekt__body__stavka .left {
    flex: initial;
    margin-right: 0;
  }
  .stavla__input .input {
    width: calc(100% - 70px);
  }
  .proekt__body__stavka__row {
    flex-direction: column;
  }
  .proekt__body__stavka__row .row__left {
    flex: initial;
    margin-right: 0;
    margin-bottom: 15px;
  }
  .proekt__body__stavka__row .row__right {
    flex: initial;
    max-width: 215px;
  }
  .proekt__detail--new .proekt__more .btn {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .proekt__body {
    padding-top: 25px;
    margin-top: 25px;
  }
}

.proekt__nav {
  border-bottom: 1px solid #a1a4b1;
  padding-top: 40px;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    width: 100%;
  }
  li {
    list-style: none;
    margin-right: 52px;
    min-width: max-content;
  }
  a {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #0a243f;
    border-bottom: 1px solid transparent;
    display: block;
    &.active {
      color: #2b5cfb;
      border-bottom: 1px solid #2b5cfb;
    }
  }
}

@media (max-width: 767px) {
  .proekt__nav {
    li {
      margin-right: 15px;
    }
    a {
      font-size: 18px;
      line-height: 1.5;
    }
  }
}
.my-proekts-list__btn-offers {
  padding: 6px 20px;
  font-size: 14px;
  line-height: 20px;
}
.proekt__more__hr {
  max-width: 920px;
  margin: 0 auto;
  border-top: 1px solid #e0e4ef;
  margin-top: 45px;
}
.project__candidates {
  padding-top: 22px;
  .project__candidates__item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.form-check__err-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #e54934;
  padding-left: 29px;
  a {
    color: #1d83e2;
    text-decoration: underline;
  }
}
.proekt__body__stavka .form-check .form-check-label.err {
  color: #e54934;
  font-weight: 700;
}
.clearCalendar {
  padding: 0;
  background-color: transparent;
  border: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  width: 18px;
}

@media (max-width: 575px) {
  .filter-relevant h2 {
    font-size: 20px;
  }
}
