:root {
  --color-red: rgba(235, 87, 87, 1);
  --color-dark: #0a243f;
  --color-grey: #8b90a0;
  --color-blue: #0f47c0;
}
$theme-colors: (
  'primary': #0f47c0,
  'danger': #dc3545,
  'success': #198754,
);

@import '~bootstrap/scss/bootstrap';

body,
html {
  height: 100%;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  flex: 1;
}
.wrap {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1410px;
  width: 100%;
}
html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  background: #f1f6fa;
  letter-spacing: -0.015em;
  color: #0a243f;
  min-width: 320px;
}

img {
  max-width: 100%;
}
video {
  display: block;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; // stylelint-disable-line declaration-no-important
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}
.invalid-feedback {
  color: #f2a60d;
}
.btn-primary {
  background-color: #0f47c0;
  border-color: #0f47c0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.btn-danger {
  color: #fff;
}
.btn.btn-danger:hover {
  color: #fff;
}
.btn-primary:hover {
  background-color: #092c9e;
  border-color: #092c9e;
}

.btn {
  font-size: 16px;
  line-height: 24px;
  border-radius: 20px;
  padding: 0.45rem 1.4rem;
  font-weight: bold;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.btn.btn-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.btn-outline-primary {
  border: 1px solid #d7d6dc;
  color: #0d6efd;
}
@media (max-width: 1450px) {
  .wrap {
    max-width: 1310px;
  }
}
@media (max-width: 374px) {
  .wrap {
    padding: 0 15px;
  }
}
#recapcha {
  margin-bottom: 15px;
}
.react-select {
  .react-select__control {
    height: 40px;
    padding: 0 14px;
    transition: 0s;
    border: 1px solid #d7d6dc;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 15%);
    border-radius: 20px;
    background: #fff;
    cursor: pointer;
  }

  .react-select__control:hover {
    border: 1px solid #d7d6dc;
  }
  &.react-select--border .react-select__control {
    border: 1px solid #a1a4b1;
    box-shadow: none;
  }
  .react-select__control--menu-is-open {
    border: 1px solid #2b5cfb;
  }
  &.react-select--border .react-select__control--menu-is-open {
    border: 1px solid #2b5cfb;
  }
  .react-select__value-container {
    padding: 0;
  }
  .react-select__menu {
    margin-top: 0;
  }
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option {
    background-color: white;
    color: var(--color-dark);
    cursor: pointer;
    &--is-focused {
      background-color: #d7d6dcdc;
      color: var(--color-dark);
    }
    &--is-selected {
      background-color: #0f47c0;
      color: #fff;
    }
  }
  .react-select__indicator {
    background-image: url('../assets/img/about/select-img.svg');
    background-position: center;
    background-repeat: no-repeat;
    padding: 12px;
    padding-right: 0;
    svg {
      display: none;
    }
  }

  .react-select__placeholder {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #79758a;
  }
  .react-select__single-value {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #000;
  }

  .react-select__control--menu-is-open {
    border-radius: 20px 20px 0 0;
    border: 1px solid #2b5cfb;
    .react-select__indicator {
      transform: rotate(180deg);
    }
  }
  &.react-select--is-disabled {
    .react-select__control {
      background-color: #e9ecef;
    }
    .react-select__indicators {
      opacity: 0;
    }
  }
  &.zero .react-select__single-value {
    color: #79758a;
  }
}
.react-select.react-select--no-shadow {
  margin-bottom: 5px;
  .react-select__control {
    box-shadow: none;
    border: 1px solid #a1a4b1;
  }
  .react-select__control--menu-is-open {
    border: 1px solid #2b5cfb;
  }
}
.multi-selectize-performer {
  .react-select {
    .react-select__clear-indicator,
    .react-select__dropdown-indicator {
      display: none;
    }
    .react-select__multi-value {
      background: #dbeefb;
      border-radius: 15px;
      padding: 4px 10px;
      padding-right: 5px;

      border: 0;
    }
    .react-select__multi-value__remove {
      border-radius: 100%;
      color: #0d6efd;

      margin-left: 5px;
      svg {
        transform: scale(0.8);
      }
    }
    .react-select__multi-value__remove:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    .react-select__multi-value__label {
      font-weight: 700;
      font-size: 12px;
      font-family: 'Mulish';
      color: #053ffa;
      line-height: 1.4;
    }
    .react-select__control {
      padding-right: 5px;
      padding-left: 5px;
      border-radius: 15px;
      height: auto;
      min-height: 48px;
    }
  }
}
.is-invalid .react-select .react-select__control {
  border: 1px solid var(--color-red);
}
.selectize-search {
  .input {
    height: 48px;
    margin-bottom: 0;
    border: 1px solid #d7d6dc;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 15%);
    padding-left: 41px;
  }
}
.modal-content {
  max-width: max-content;
  margin: 0 auto;
  border-radius: 15px;
}
.modal.show .modal-dialog {
  margin: 0;
  padding: 15px;
}
@media (min-width: 1500px) {
  .modal.show .modal-dialog {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.text-danger {
  display: block;
}
.error + .invalid-feedback {
  color: #f2a60d;
  display: block;
  margin-top: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.tabs__item {
  display: none;
}

.tabs__item.active {
  display: block;
}

.btn:disabled {
  opacity: 0.5;
}

.fadeIn {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.modal-text {
  padding: 29px 27px;
  height: 450px;
  overflow: auto;
  background: #f1f6fa;
}

.exp-modal.info-modal {
  .modal__close {
    border: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff;
  }
  .modal-header {
    position: relative;
    padding-right: 55px;
    margin-bottom: 8px;
  }
}
@media (max-width: 767px) {
  .modal-text {
    padding: 15px;
  }
}

blockquote {
  font-size: 1.2em;
  line-height: 1.5;
  color: #666;
  border-left: 5px solid #ccc;
  padding-left: 20px;
  margin: 20px 0;
}
@media (max-width: 1200px) {
  .helpcrunch-iframe-wrapper iframe {
    right: 0 !important;
    bottom: 0 !important;
  }
}

@import './pages/about';
@import './pages/resume';
@import './pages/portfolio';
@import './pages/proekt';
@import './pages/statistic';
@import './pages/profile';
@import './pages/application';
@import './pages/company';
@import './pages/auth';
@import './pages/proekt-public';
@import './pages/customs';
