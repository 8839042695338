.m_app {
  padding-top: 72px;
  background-color: #fff;
}
.m_header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: #fff;
  opacity: 1;
}

.m_header__wrap {
  display: flex;
  align-items: center;
  height: 72px;
}
.m_header__logo {
  display: flex;
  align-items: center;
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #1d4289;
  margin-right: 43px;
  img {
    margin-right: 5px;
    height: 40px;
  }
}
.header__menu {
  ul {
    display: flex;
    padding: 0;
    margin: 0;
  }
  li {
    margin-right: 82px;
    list-style: none;
  }
  a {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #001828;
    transition: 0.3s all;
    &:hover {
      color: #0a58ca;
    }
  }
}
.header__auth {
  flex: 1;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    color: #262a39;
    display: block;
    margin-left: 24px;
    transition: 0.3s all;
    &:hover {
      color: #0a58ca;
    }
  }
  .header__auth__signUp {
    color: #ffffff;
    padding: 6px 34px;
    background: #262a39;
    border-radius: 20px;
    transition: 0.3s all;
    border: 0;
    &:hover {
      color: #ffffff;
      background: #000;
    }
  }
}
.header__sub {
  background: #262a39;
  position: relative;
  z-index: 3;
}
.header__sub__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__sub__menu {
  display: flex;
  min-height: 64px;
  padding: 0;
  margin: 0;
  .header__sub__menu__item {
    margin-right: 94px;
    list-style: none;
    position: relative;
    & > a {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      display: block;
      padding: 20px 0;
      text-align: center;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
    &:first-child {
      padding-left: 2px;
    }
  }
}

.header__sub__menu__level2 {
  padding: 24px 56px;
  position: absolute;
  top: 100%;
  left: -56px;
  background: #262a39;
  width: 424px;
  margin: 0;
  transition: 0.3s all;
  display: none;
  z-index: 3;
  border-top: 1px solid #fff;
  li {
    list-style: none;
  }
  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;

    &:hover {
      text-decoration: underline;
    }
  }
}
@media (min-width: 992px) {
  .header__sub__menu__item:hover {
    .header__sub__menu__level2 {
      display: block;
    }
  }
}

.s1 {
  padding-top: 80px;
  padding-bottom: 111px;
  overflow: hidden;
  background-color: #f4f7ff;
}
.s1__wrap {
  position: relative;
}
.s1__content {
  z-index: 2;
  position: relative;
  opacity: 0;
  transition: 0.3s all;
}
.s1__wrap.active {
  .s1__content {
    opacity: 1;
  }
}

.s1__title {
  font-weight: 500;
  font-size: 80px;
  line-height: 1.1;
  margin-bottom: 101px;
  color: #000000;
  max-width: 900px;
}
.s1__form {
  position: relative;
  max-width: 494px;
  margin-bottom: 31px;
  input {
    border: 1px solid #262a39;
    border-right: 0;
    border-radius: 50px 0 0 50px;
    background-color: transparent;
    height: 52px;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;

    &::placeholder {
      color: #424242;
    }
  }
  button {
    position: absolute;
    border-radius: 0 50px 50px 0;
    border: 1px solid #262a39;
    z-index: 1;
    top: 0;
    right: 0;
    transform: translateX(100%);
    height: 100%;
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    padding-right: 3px;
    transition: 0.3s all;
    &:hover {
      background-color: #f2f1f3;
    }
  }
}
.m_app {
  .tags {
    display: flex;
    flex-wrap: wrap;
    max-width: 560px;
    margin-bottom: 60px;
    .tags__item {
      margin-right: 8px;
      font-weight: 600;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #fafbfa;
      padding: 8px 34px;
      background: #262a39;
      border-radius: 40px;
      margin-bottom: 12px;
    }
  }
}

.s1__text {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  max-width: 688px;
  color: #0a243f;
  margin-bottom: 24px;
}
.m_btn {
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  height: 60px;
  padding: 0 15px;
  min-width: 286px;
  color: #ffffff;
  background: #0f47c0;
  border-radius: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  text-transform: uppercase;
  &:hover {
    color: #fff;
    background-color: #0a58ca;
  }
}
.m_btn.m_btn--black {
  background: #262a39;
  &:hover {
    background-color: #000;
  }
}
.s1__video {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
}
.s1__wrap.active {
  .s1__video {
    right: -90px;
  }
}
.s1__video video {
  display: none;
  max-width: 100%;
  pointer-events: none;
  width: 100%;
}

@media (min-width: 992px) {
  .s1__video .video__xl {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .s1__video .video__md {
    display: block;
  }
}

@media (max-width: 767px) {
  .s1__video .video__sm {
    display: block;
  }
  .s1__link {
    display: flex;
    justify-content: center;
    margin-top: 25px;
  }
}

.header__lang {
  position: relative;
}
.header__lang__m_btn {
  padding: 0;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  svg:last-child {
    stroke: white;
  }
}
.header__lang__name {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 0 8px;
  display: block;
  text-transform: capitalize;
}
.header__lang__toggle {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #262a39;
  &.active {
    display: block;
  }
  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background-color: transparent;
    display: block;
    &:hover {
      background-color: #fff;
      color: #262a39;
    }
  }
}
.mobBurger {
  display: none;
}
.header__sub__menu__item__m_btn {
  display: none;
}
@media (max-width: 1299px) {
  .header__sub__menu {
    .header__sub__menu__item {
      margin-right: 35px;
    }
  }
  .header__menu li {
    margin-right: 35px;
  }
}

.header__sub__mob {
  display: none;
}

@media (max-width: 991px) {
  .mobBurger {
    display: block;
    width: 30px;
    min-width: 30px;
    height: 20px;
    padding: 0;
    margin-right: 15px;
    background-color: transparent;
    border: 0;
    position: relative;
    .mobBurger__line {
      height: 2px;
      width: 100%;
      background-color: #000;
      position: absolute;
      transition: 0.3s all;
    }
    .mobBurger__line:first-child {
      top: 1px;
    }
    .mobBurger__line:nth-child(2) {
      top: calc(50% - 1px);
    }
    .mobBurger__line:last-child {
      bottom: 1px;
    }
  }
  .mobBurger.active {
    .mobBurger__line:first-child {
      transform: rotate(45deg);
      top: 9px;
    }
    .mobBurger__line:nth-child(2) {
      opacity: 0;
    }
    .mobBurger__line:last-child {
      transform: rotate(-45deg);
      bottom: 9px;
    }
  }
  .header__sub {
    position: fixed;
    top: 72px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    overflow-y: auto;
    padding-top: 35px;
    padding-bottom: 25px;
    transform: translateX(-100%);
    transition: 0.3s all;
    &.active {
      transform: translateX(0);
    }
  }
  .header__sub__wrap {
    flex-direction: column;
  }
  .header__sub__menu {
    flex-direction: column;
    .header__sub__menu__item > a {
      padding: 0;
      font-size: 20px;
      line-height: 28px;
    }
  }
  .header__sub__menu__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .header__sub__menu .header__sub__menu__item {
    margin-right: 0;
  }
  .header__sub__menu__item__m_btn {
    display: block;
    background-color: transparent;
    padding: 0;
    border: 0;
    margin-left: 10px;
  }
  .header__sub__menu__level2 {
    position: initial;
    width: 100%;
    padding: 0;
    border: 0;
    margin-bottom: 15px;
    margin-top: 15px;
    li {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
    a {
      text-align: center;
    }
  }

  .header__sub__menu__item.active {
    .header__sub__menu__item__m_btn {
      transform: rotateX(180deg);
    }
    .header__sub__menu__level2 {
      display: block;
    }
  }
  .header__menu {
    display: none;
  }
  .header__sub__mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header__menu {
      display: block;
    }
    .header__menu ul {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .header__menu li {
      margin-right: 0;
      margin-bottom: 15px;
      a {
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 767px) {
  .header__auth {
    a {
      display: none;
    }
    .header__auth__signUp {
      display: block;
      margin-left: 0;
    }
  }
  .m_header__logo {
    font-size: 0;
    min-width: 45px;
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .s1__title {
    font-size: 50px;
    line-height: 65px;
    margin-bottom: 50px;
  }
  .s1__text {
    max-width: 535px;
  }
}

@media (max-width: 991px) {
  .s1 {
    padding-top: 20px;
  }
  .s1__title {
    font-size: 55px;
    line-height: 80px;
    margin-bottom: 25px;
  }
  .s1__form {
    max-width: 330px;
  }
  .m_app {
    .tags .tags__item {
      padding: 6px 25px;
    }
    .tags {
      margin-bottom: 25px;
      max-width: 420px;
    }
  }

  .s1__text {
    max-width: 400px;
    font-size: 16px;
    line-height: 25px;
  }
  .m_btn {
    height: 50px;
    width: 250px;
  }
  .s1__wrap.active .s1__video {
    right: -20px;
    height: 168vw;
    top: 70px;
  }
  .s1__form input {
    font-size: 18px;
  }
}
.s1__title--mob {
  display: none;
}
@media (max-width: 767px) {
  .s1__wrap {
    display: flex;
    flex-direction: column-reverse;
    .s1__content {
      opacity: 1;
    }
  }
  .s1__video {
    position: initial;
  }
  .s1__wrap .s1__video {
    margin-top: -35%;
    margin-bottom: -35%;
  }

  .s1 .s1__title {
    display: none;
  }
  .s1 .s1__title--mob {
    display: block;
    position: relative;
    z-index: 2;
  }
  .s1__title {
    font-size: 27px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  .s1 {
    padding-top: 35px;
    padding-bottom: 50px;
  }
}

@media (max-width: 500px) {
  .s1__form {
    max-width: 100%;
    margin-bottom: 25px;
  }
  .s1__form input {
    max-width: calc(100% - 66px);
    font-size: 16px;
    padding: 0 15px;
  }
  .s1__form button {
    transform: translateX(0);
  }
  .m_app {
    .tags .tags__item {
      margin-right: 5px;
      margin-bottom: 10px;
      padding: 6px 19px;
    }
    .tags {
      justify-content: center;
    }
  }
  .s1__text {
    text-align: center;
  }
  .s1__title {
    text-align: center;
  }
}

.s2 {
  padding-top: 80px;
  padding-bottom: 120px;
}
.s2__wrap {
  display: flex;
  align-items: flex-start;
  background: linear-gradient(280.47deg, #f1f6fa 2.59%, rgba(250, 250, 250, 0) 86.33%);
  border-radius: 20px;
  margin-top: 100px;
}
.s2__left {
  flex: 0 0 452px;
  width: 452px;
  overflow: hidden;
  border-radius: 20px 0px 0px 20px;
  margin-top: -81px;
  video {
    width: 100%;
  }
}
.s2__right {
  padding-left: 81px;
  padding-top: 22px;
  position: relative;

  flex: 1;
  padding-bottom: 26px;
  padding-right: 15px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -31px;
    max-width: 100%;
    width: 425px;
    border-top: 2px solid #d9d9d9;
  }
}
.s2__label {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  margin-bottom: 37px;
  color: #0f47c0;
}
.s2__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: #0a243f;
  margin-bottom: 29px;
  max-width: 380px;
}
.s2__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-bottom: 75px;
  max-width: 565px;
}

@media (max-width: 1199px) {
  .s2__right {
    padding-left: 35px;
  }
}

@media (max-width: 991px) {
  .s2__left {
    flex: 0 0 352px;
    width: 352px;
    height: auto;
  }
  .s2 {
    padding-bottom: 75px;
  }
}

@media (max-width: 767px) {
  .s2__wrap {
    flex-direction: column-reverse;
    margin-top: 50px;
  }
  .s2__text {
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 24px;
  }
  .s2__left {
    margin-top: 35px;
    margin-left: auto;
    flex: auto;
    width: 352px;
    max-width: 100%;
    height: auto;
  }
  .s2 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .s2__right:before {
    left: -15px;
  }
  .s2__right {
    padding-left: 0;
  }
  .s2__title {
    font-size: 28px;
    line-height: 34px;
  }
  .s2__label {
    margin-bottom: 25px;
  }
}

.s3 {
  padding-top: 120px;
  padding-bottom: 123px;
  overflow: hidden;
}

.s3__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -30px;
}
.s3__item {
  width: calc(50% - 60px);
  margin: 0 30px;
  margin-bottom: 30px;
}
.m__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  color: #0a243f;
}
.s3__item {
  background-color: #fafafa;
  padding: 60px 34px;
  border-radius: 0px 0px 150px 0px;

  .m__title {
    margin-bottom: 43px;
  }
}
.s3__item:last-child {
  margin-top: 131px;
}
.s3__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-bottom: 43px;
}
.s3__img {
  display: block;
  margin-left: auto;
}

@media (max-width: 1199px) {
  .s3__img {
    margin-top: 25px;
  }
}

@media (max-width: 991px) {
  .s3__item {
    width: calc(50% - 30px);
    margin: 0 15px;
  }
  .s3__wrap {
    margin: 0 -15px;
  }
  .s3__item:last-child {
    margin-top: 75px;
  }
  .s3 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .s3__wrap {
    flex-direction: column;
    margin: 0;
  }
  .s3__item {
    width: 100%;
    margin: 0;
    margin-bottom: 35px;
    padding: 40px 15px;
  }
  .s3__item:last-child {
    margin-top: 0;
  }
  .m__title {
    font-size: 28px;
    line-height: 35px;
  }
  .s3__item .m__title {
    margin-bottom: 30px;
  }
  .s3__text {
    font-size: 16px;
    line-height: 1.7;
  }
}

.s4 {
  padding-top: 50px;
  padding-bottom: 125px;
  overflow: hidden;
}
.s4__wrap {
  display: flex;
  align-items: flex-start;
}
.s4__img1 {
  max-width: 432px;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 255px;
}
.s4__left {
  width: 50%;
  padding-right: 133px;
}
.s4__left__text {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  max-width: 432px;
  margin-left: auto;
  margin-bottom: 197px;
}
.s4__img2 {
  max-width: 319px;
  margin-left: auto;
  border-radius: 20px;
  overflow: hidden;
}
.s4__right {
  padding-top: 85px;
  width: 50%;
  .m__title {
    margin-bottom: 67px;
  }
}
.s4__label {
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  font-family: 'Mulish';
  color: #0f47c0;
  margin-bottom: 27px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -14px;
    width: 100vw;
    left: 0;
    border-bottom: 2px solid #d9d9d9;
  }
}
.s4__video {
  border-radius: 20px;
  overflow: hidden;
  video {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

@media (max-width: 1199px) {
  .s4__left {
    padding-right: 25px;
  }
  .s4__img1 {
    margin-bottom: 100px;
  }
  .s4__left__text {
    margin-bottom: 100px;
  }
}
@media (max-width: 991px) {
  .s4 {
    padding-bottom: 75px;
  }
}
@media (max-width: 550px) {
  .s4__wrap {
    flex-direction: column-reverse;
  }
  .s4__left {
    padding-right: 0;
    width: 100%;
  }
  .s4__right {
    padding-top: 0;
    width: 100%;
    margin-bottom: 35px;
  }
  .s4__right .m__title {
    margin-bottom: 25px;
  }
  .s4__img1 {
    margin-bottom: 35px;
  }
  .s4__left__text {
    margin-bottom: 35px;
  }
  .s4__img2 {
    margin-left: 0;
  }
}

.s5 {
  background: #f4f7ff;
  padding-top: 66px;
  padding-bottom: 76px;
}
.s5__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;
  color: #0a243f;
  margin-bottom: 91px;
}
.s5__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.s5__col {
  width: calc(100% / 3 - 28px);
  margin: 0 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.s5__img {
  margin-bottom: 45px;
  display: block;
}
.s5__cifra {
  font-weight: 600;
  font-size: 64px;
  line-height: 68px;
  margin-bottom: 25px;
  text-align: center;
  color: #0a243f;
}
.s5__text {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #0a243f;
}

@media (max-width: 991px) {
  .s5__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 50px;
  }
  .s5__cifra {
    font-size: 50px;
    line-height: 54px;
    margin-bottom: 20px;
  }
  .s5__text {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (max-width: 767px) {
  .s5__col {
    width: calc(100% - 28px);
    margin-bottom: 75px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .s5__img {
    margin-bottom: 15px;
  }
  .s5__title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 50px;
  }
  .s5__cifra {
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 15px;
  }
  .s5 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.s6 {
  padding-top: 120px;
}
.s6__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  text-transform: uppercase;
  color: #0a243f;
  margin-bottom: 26px;
  max-width: 929px;
}

.s6__tabs {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 55px;
  button {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #79758a;
    background-color: transparent;
    border: 0;
    position: relative;
    padding: 0;
    &:last-child {
      margin-left: 76px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      color: #0f47c0;
    }
    &.active {
      color: #0f47c0;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        border-bottom: 2px solid #092c9e;
      }
    }
  }
}
.s6__tabs__wrap {
  border-bottom: 1px solid #d9d9d9;
}
@media (max-width: 991px) {
  .s6 {
    padding-top: 75px;
  }
}

@media (max-width: 767px) {
  .s6 {
    padding-top: 50px;
  }
}
.s6__tabcontent {
  display: none;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 -10px;
  &.active {
    display: flex;
  }
}
.s6__col {
  width: calc(50% - 20px);
  margin: 0 10px;
  margin-bottom: 252px;
  padding: 102px 44px;
  padding-bottom: 135px;
  min-height: 648px;
  position: relative;
  background: linear-gradient(313.49deg, #d3daec 8.85%, #fafafa 99.61%);
  border-radius: 0px 0px 150px 0px;
  &:nth-child(2n) {
    transform: translateY(119px);
  }
}
.s6__col__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 1;
  color: #3a3a3a;
  margin-bottom: 36px;
}
.s6__col__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  max-width: 515px;
  margin-bottom: 49px;
}
.s6__num {
  font-weight: 400;
  font-size: 200px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: rgba(37, 37, 37, 0.9);
  position: absolute;
  right: 42px;
  bottom: 0;
  transform: translateY(45px);
  font-family: 'Mulish';
}

@media (max-width: 1199px) {
  .s6 {
    padding-bottom: 75px;
  }
  .s6__col {
    padding: 70px 30px;
    padding-bottom: 135px;
    margin-bottom: 150px;
  }
  .s6__col__title {
    font-size: 30px;
  }
  .s6__col__text {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (max-width: 991px) {
  .s6 {
    padding-bottom: 100px;
  }
  .s6__title {
    font-size: 40px;
    line-height: 48px;
  }
  .s6__tabs {
    margin-bottom: 35px;
  }
  .s6__tabs button {
    font-size: 26px;
    line-height: 36px;
  }
  .s6__num {
    font-size: 150px;
  }
  .s6__col {
    padding: 30px 15px;
    min-height: initial;
    padding-bottom: 120px;
    margin-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .s6__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  .s6__tabs button:first-child {
    margin-right: 25px;
  }
  .s6__col {
    min-height: 400px;
    width: calc(100% - 20px);
    margin-bottom: 75px;
  }
  .s6__col:nth-child(2n) {
    transform: translate(0);
  }
  .s6__tabs button {
    font-size: 20px;
    line-height: 28px;
  }
  .s6__col__title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .s6__col__text {
    font-size: 16px;
    margin-bottom: 35px;
  }
  .s6__col {
    .m_btn {
      height: 45px;
      width: 200px;
      max-width: 100%;
    }
  }
  .s6__num {
    font-size: 120px;
  }
  .s6 {
    padding-bottom: 50px;
  }
}

.s7 {
  min-height: 100vh;
  max-height: 1000px;
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  padding-top: 89px;
  padding-bottom: 50px;
}
.s7:before {
  content: '';
  display: block;
  position: absolute;
  background: linear-gradient(90deg, #c2c4c7 27.29%, rgba(145, 143, 139, 0) 122.27%);
  top: 0;
  right: 50%;
  bottom: 0;
  width: 100vw;
  z-index: 1;
  transform: translateX(-150px);
}
@media (max-width: 1300px) {
  .s7:before {
    transform: translate(0);
    right: auto;
    left: 0;
    width: 500px;
  }
}
@media (max-height: 600px) {
  .s7 {
    min-height: 600px;
  }
}
.s7__wrap {
  max-width: 483px;
  position: relative;
  z-index: 2;
}
.s7__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  color: #000000;
  margin-bottom: 13px;
}
.s7__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 50px;
}

@media (max-width: 992px) {
  .s7 {
    height: 600px;
    max-height: initial;
    position: relative;
    background-attachment: initial;
  }
  .s7__title {
    font-size: 24px;
    line-height: 32px;
  }
  .s7__text {
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 767px) {
  .s7 {
    background-size: 100% auto;
    background-position: top left;
    background-repeat: no-repeat;
    padding-bottom: 0;
    padding-top: 106vw;
    height: auto;
    .wrap {
      background: #c2c4c7;
      padding-top: 18px;
      padding-bottom: 40px;
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 144px;
        width: 100%;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        background: linear-gradient(0.3deg, #c2c4c7 0.22%, rgba(145, 143, 139, 0) 101.05%);
      }
    }
  }
  .s7:before {
    display: none;
  }
  .s7__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 24px;
  }
  .s7__text {
    margin-bottom: 24px;
  }
}

.s8 {
  padding-top: 120px;
  padding-bottom: 120px;
  overflow: hidden;
}
.s8__wrap {
  display: flex;
}
.s8__left {
  flex: 0 0 436px;
  margin-right: 120px;
  button {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #afacb9;
    margin-bottom: 11px;
    padding: 0;
    border: 0;
    background-color: transparent;
    display: block;
    &.active {
      color: #0f47c0;
    }
    &:hover {
      color: #0f47c0;
    }
  }
}
.s8__right {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  ul {
    padding: 0;
    margin: 0;
    &:last-child {
      margin-right: 0;
    }
    li {
      list-style: none;
      margin-bottom: 5px;
    }
    a {
      color: inherit;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.s8__title {
  font-weight: 600;
  font-size: 48px;
  line-height: 52px;
  text-transform: uppercase;
  color: #262a39;
  margin-bottom: 60px;
}
@media (max-width: 1199px) {
  .s8__left {
    margin-right: 50px;
    flex: 0 0 350px;
  }
  .s8__right ul {
    margin-right: 50px;
  }
  .s8__left button {
    font-size: 24px;
    line-height: 32px;
  }
}

@media (max-width: 991px) {
  .s8 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}
@media (max-width: 767px) {
  .s8__left {
    margin-right: 0;
    flex: auto;
    width: 100%;
  }
  .s8__right {
    display: none;
  }
  .s8__left .s8__right {
    display: block;
    margin-bottom: 15px;
  }
  .s8__left button {
    font-size: 20px;
    line-height: 26px;
  }
  .s8__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  .s8 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.s9 {
  padding-top: 18px;
  padding-bottom: 18px;
  .content {
    max-height: 68px;
    position: relative;
    overflow: hidden;
    h2 {
      font-size: 1.5rem;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 83px;
      left: 0;
      width: 100%;
      background: linear-gradient(0deg, #fafbfa 0%, rgba(250, 251, 250, 0.41) 100%);
    }
    &.active {
      max-height: initial;
      &:after {
        display: none;
      }
    }
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;

    color: #000000;
  }
}
.s9__wrap {
  position: relative;
}
.s9__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
}
.s9__btn {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 5px 0;
    background-color: transparent;
    border: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.015em;
    text-decoration-line: underline;

    color: #000000;
  }
}
.m_app {
  .footer {
    margin-top: 50px;
  }
}
.s1__mainImg {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .s1__mainImg {
    position: relative;
    justify-content: center;
    img {
      max-width: 100%;
      width: 100%;
    }
  }
  .s3__img {
    margin-left: auto;
    margin-right: auto;
  }
}
