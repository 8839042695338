.upload-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.upload-wrap__col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-dark);
  padding: 40px;
  img {
    margin-bottom: 15px;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 1200px) {
  .upload-wrap--center {
    max-width: 100%;
    padding-right: 25px;
    .upload-wrap {
      width: 100%;
      justify-content: center;
    }
  }
}
.upload-wrap {
  margin-top: 25px;
  margin-bottom: 25px;
}
.upload-wrap__col {
  border: 1px dashed transparent;
  width: 250px;
  padding: 30px;
  margin: 0;
  cursor: pointer;
  position: relative;
}
.upload-wrap__col__button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  background-color: transparent;
  display: none;
}
.upload-wrap__text {
  text-align: center;
}
.upload-wrap__text--file {
  text-overflow: ellipsis;
  overflow: hidden;
  display: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 250px;
  text-align: center;
}
.upload-wrap__col:hover {
  border: 1px dashed #0a58ca;
  cursor: pointer;
}
.upload-wrap__col.upload:hover {
  .upload-wrap__col__button {
    display: block;
  }
}

.upload-wrap__col.hover {
  border: 1px dashed #0a58ca;
}
.upload-wrap__col.upload {
  .upload-wrap__text {
    display: none;
  }
  .upload-wrap__text--file {
    display: -webkit-box;
  }
}
@media (max-width: 991px) {
  .upload-wrap__col.upload {
    border: 1px dashed #0a58ca;
    cursor: pointer;
  }
  .upload-wrap__col.upload {
    .upload-wrap__col__button {
      display: block;
    }
  }
}
// .upload-wrap__col:last-child{
//     margin-right: 0;
// }

.upload-wrap__btns {
  display: flex;
  flex-wrap: wrap;
  button {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
@media (max-width: 991px) {
  .upload-wrap__col {
    padding: 0 15px;
    margin-right: 0;
    width: 50%;
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .upload-wrap {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
  .upload-wrap__col {
    margin-right: 0;
    margin-bottom: 50px;
    padding: 0;
    width: 100%;
  }
  .upload-wrap__btns {
    justify-content: center;
  }
}

.exp-content__wrap--resume {
  max-width: initial;
  padding-right: 25px;
  .upload-wrap__btns {
    justify-content: center;
    padding-right: 27px;
    margin-top: 25px;
    .btn {
      margin-right: 0;
    }
  }
}

@media (max-width: 991px) {
  .exp-content__wrap--resume {
    padding-right: 0;
    .upload-wrap__btns {
      padding-right: 0;
      margin-top: 0;
    }
    .upload-wrap__col {
      padding: 15px;
    }
  }
}
@media (max-width: 767px) {
  .exp-content__wrap--resume {
    .upload-wrap {
      align-items: center;
    }

    .upload-wrap__col {
      max-width: 250px;
      margin-bottom: 25px;
    }
  }
}
