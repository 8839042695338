.sett-modal .modal-header {
  position: relative;
  padding-right: 55px;
  margin-bottom: 8px;
}
.sett-modal__close {
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 15px;
  right: 15px;
}

.sett-modal__nav {
  background: #262a39;
  padding: 8px 27px 0;
  overflow: auto;
  display: flex;
  .btn {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    padding: 12px 32px;
    border: 0;
    border-radius: 10px 10px 0px 0px;
    &:hover,
    &:focus {
      color: #ffffff;
    }
  }
  .btn.active {
    color: #022964;
    background: #f1f6fa;
  }
}
.sett-modal-body {
  padding: 29px 27px;
  min-height: 412px;
  background: #f1f6fa;
}
.sett-modal__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
  margin-bottom: 6px;
}
.sett-modal__top {
  margin-bottom: 24px;
}
.sett-modal__pass__input {
  position: relative;
  max-width: 494px;
  margin-bottom: 10px;
  input {
    padding-right: 35px;
  }
  button {
    padding: 0;
    width: 24px;
    border: 0;
    background-color: transparent;
    height: 24px;
    position: absolute;
    right: 12.5px;
    top: 8px;
  }
  button .show {
    display: none;
  }
  button.active .hide {
    display: none;
  }
  button .hide {
    display: block;
  }
  button svg {
    width: 100%;
    height: 100%;
    fill: #615e6e;
  }
  .btn.active {
    color: #022964;
    background: #f1f6fa;
  }
}
.sett-modal__pass__t {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 2px;
  span {
    color: var(--color-red);
  }
}
.sett-modal__checkbox {
  display: flex;
  margin-bottom: 14px;
  cursor: pointer;
  margin-top: 18px;
  margin-bottom: 10px;
}
.sett-modal__checkbox input {
  position: absolute;
  z-index: -99;
  opacity: 0;
}
.sett-modal__checkbox input:checked + .sett-modal__checkbox__check {
  border: 3px solid #18171c;
}

.sett-modal__checkbox input + .sett-modal__checkbox__check {
  border: 3px solid #a1a4b1;
  border-radius: 3px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  position: relative;
  margin-right: 11px;
}

.sett-modal__checkbox input:checked + .sett-modal__checkbox__check svg {
  opacity: 1;
}

.sett-modal__checkbox input + .sett-modal__checkbox__check svg {
  position: absolute;
  top: -2px;
  left: 1px;
  opacity: 0;
}
.sett-modal {
  .upload-wrap__btns {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .sett-modal__close {
    right: 15px;
    top: 15px;
  }
  .sett-modal__title {
    font-size: 26px;
    line-height: 36px;
  }
  .sett-modal-body {
    padding: 15px;
  }
  .sett-modal__nav {
    padding: 8px 15px 0;
  }
  .sett-modal__nav .btn {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
  }
  .sett-modal-body {
    min-height: 350px;
  }
}
