.otbor {
  padding-top: 67px;
  margin-bottom: 75px;
  .otbor__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #2b5cfb;
    border-bottom: 1px solid #a1a4b1;
    margin-bottom: 37px;
    text-align: center;
  }
  .otbor__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.7;
    color: #000000;
    text-align: center;
    margin-bottom: 81px;
    padding-right: 34px;
    padding-left: 34px;
    button {
      background-color: transparent;
      border: 0;
      padding: 0;
      color: #2b5cfb;
    }
  }
  .otbor__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .btn {
      margin: 0 10px;
      margin-bottom: 15px;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.otbor__timer {
  margin-right: -12px;
  margin-left: -12px;
  margin-bottom: -12px;
  background: #eaf6ff;
  padding-top: 52px;
  padding-bottom: 56px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding-right: 15px;
  padding-left: 15px;
}
.otbor__timer__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 46px;
  max-width: 821px;
}
.otbor__timer__flex {
  display: flex;
  justify-content: center;
  margin: 0 -52px;
}
.otbor__timer__item {
  margin: 0 52px;
  text-align: center;
  font-weight: 400;
  font-size: 54px;
  line-height: 1;
  margin-bottom: 15px;
}
.otbor__timer__type {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}
.timer--small {
  .otbor__timer__item {
    margin: 0 10px;
    font-size: 40px;
  }
  .otbor__timer__title {
    margin-bottom: 20px;
  }
  .otbor__timer__flex {
    margin: 0 -10px;
  }
  .otbor__timer__type {
    font-size: 18px;
    line-height: 22px;
  }
  .otbor__timer {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media (max-width: 991px) {
  .otbor__timer__title {
    font-size: 16px;
    line-height: 22px;
  }
  .otbor__timer__flex {
    margin: 0 -25px;
  }
  .otbor__timer__item {
    margin: 0 25px;
  }
  .otbor {
    padding-top: 50px;
    .otbor__text {
      margin-bottom: 50px;
    }
  }
}
@media (max-width: 767px) {
  .otbor {
    margin-bottom: 35px;
    padding-top: 15px;
    .otbor__title {
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 24px;
    }
    .otbor__text {
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 40px;
    }
    .otbor__btn {
      .btn {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
  .otbor__timer__item {
    margin: 0 10px;
    font-size: 40px;
  }
  .otbor__timer__title {
    margin-bottom: 20px;
  }
  .otbor__timer__flex {
    margin: 0 -10px;
  }
  .otbor__timer__type {
    font-size: 18px;
    line-height: 22px;
  }
  .otbor__timer {
    padding-top: 35px;
    padding-bottom: 35px;
  }
}
@media (max-width: 565px) {
  .otbor {
    .otbor__btn {
      flex-direction: column;
      .btn {
        font-size: 14px;
        margin: 0;
        margin-bottom: 15px;
        padding: 0.45em 1em;
      }
    }
  }
}
.otbor__cat__name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.otbor__qa__text {
  font-size: 18px;
  margin-bottom: 10px;
  & > div {
    display: inline;
  }
}
.otbor__cat {
  padding: 0 15px;
  max-width: 900px;
}
.otbor__qa__list {
  margin-bottom: 30px;
  label {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    input + span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      min-width: 16px;
      height: 16px;
      border-radius: 100%;
      margin-right: 8px;
      position: relative;
      background-color: #ffffff;
      border: 1px solid #d7d6dc;
      margin-top: 3px;
      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #fff;
        opacity: 0;
      }
    }
    input:checked + span {
      background-color: #2b5cfb;
      border: 1px solid transparent;
      &:after {
        opacity: 1;
      }
    }
  }
}
.otbor__btnqa {
  margin-top: 25px;
  margin-bottom: 50px;
}
.exp-content--otbor {
  align-items: flex-start;
  @media (min-width: 768px) {
    .exp-content__edit {
      min-height: 500px;
    }
  }
}
.modal__otbor-bot {
  margin-top: 39px;
  padding: 0 20px;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  .btn {
    margin-right: 18px;
    margin-bottom: 15px;
  }
}
@media (max-width: 565px) {
  .modal__otbor-bot {
    flex-direction: column;
    padding: 0;
    padding-bottom: 50px;
    .btn {
      width: 100%;
      margin-right: 0;
    }
  }
  .otbor__qa__text {
    font-size: 16px;
  }
}
.otbor__qa__img {
  margin-bottom: 25px;
  margin-top: 10px;
}
.otbor-notSpec {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.otbor-notSpec__text {
  margin-bottom: 15px;
  &:hover {
    text-decoration: underline;
  }
}
