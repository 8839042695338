.proekt__detail--my-project {
  padding-bottom: 61px;
  .new-check {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    svg {
      margin-right: 8px;
    }
  }
  .proekt__content__tags__item {
    padding: 6px 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    font-family: 'Mulish';

    color: #3c64b1;
  }
  .proekt__content__person__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0a243f;
    margin-right: 0;
  }
  .proekt__content__text {
    margin-bottom: 8px;
  }

  .proekt__detail__status {
    display: flex;
    width: 100%;
    padding-bottom: 19px;
    overflow-x: auto;
  }
  .proekt__detail__status__item {
    padding: 2px 18px;
    padding-right: 16px;
    font-weight: 700;
    font-size: 10px;
    line-height: 20px;
    color: #9491a1;
    background: #f2f1f3;
    position: relative;
    min-width: max-content;
    &:after {
      content: '';
      width: 18px;
      height: 17.5px;
      border-bottom: 2px solid #fff;
      border-right: 2px solid #fff;
      position: absolute;
      top: 3px;
      right: -9px;
      transform: rotate(-45deg);
      background: #f2f1f3;
      z-index: 2;
      pointer-events: none;
    }
    &.active {
      color: #f1f6fa;
      background: #2b5cfb;
    }
    &.active:after {
      background: #2b5cfb;
    }
    &:last-child:after {
      display: none;
    }
  }
}
.proekt__detail__flex {
  display: flex;
  .left {
    flex: 1;
    margin-right: 43px;
    width: 100%;
  }
  .right {
    flex: 0 0 248px;
  }
}
@media (max-width: 1300px) {
  .proekt__detail__flex {
    .left {
      margin-right: 15px;
    }
    .right {
      flex: 0 0 215px;
    }
  }
}

@media (max-width: 991px) {
  .proekt__detail__flex {
    flex-direction: column;
    .left {
      margin-right: 0;
    }
    .right {
      flex: initial;
      margin-bottom: 10px;
    }
  }
}

.proekt__offers {
  padding-top: 53px;

  .proekt__more {
    margin: 0;
    max-width: initial;
  }
  .proekt__tabs {
    margin: 0;
  }
  .proekt__tabs li button {
    padding: 0;
  }
  .proekt__tabs li:last-child {
    margin-right: 0;
  }
  a.proekt__content__person__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #0a243f;
    margin-right: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  .proekt__chat {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    background-color: transparent;
    padding: 0;
    border: 0;
    flex: none;
    margin-right: 0;
    svg {
      margin-left: 11px;
    }
  }
}
.Proekt__offers__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0a243f;
  margin-bottom: 25px;
}
.proekt__offers__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .proekt__actios {
    margin-bottom: 34px;
  }
}
.proekt__offers__bot {
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1300px) {
  .proekt__offers__btn-chat {
    font-size: 14px;
    line-height: 22px;
    padding: 0.35rem 1rem;
  }
}
@media (max-width: 991px) {
  .proekt__offers__right {
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
    justify-content: flex-end;
    .proekt__actios {
      margin: 0;
      margin-right: 15px;
    }
  }
  .proekt__offers {
    padding-top: 0;
  }
}

@media (max-width: 575px) {
  .proekt__offers .proekt__content__person__name {
    font-size: 14px;
    line-height: 22px;
  }
  .proekt__offers__right {
    justify-content: space-between;
  }
  .proekt__offers {
    .proekt__list__item {
      padding-top: 35px;
    }
    .proekt__content__bot {
      flex-direction: row;
    }
    .proekt__content__actions {
      margin-top: 0;
      width: auto;
      align-items: flex-end;
    }
  }
  .proekt__offers__bot {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .proekt__offers .proekt__chat {
    margin-left: auto;
    margin-top: 25px;
  }
}
@media (max-width: 374px) {
  .proekt__offers__btn-chat {
    font-size: 11px;
    line-height: 20px;
    padding: 0.35rem 1rem;
  }
}
.proekt__performer__btn-chat {
  margin-top: 36px;
}
.my-performer {
  .proekt__list__item {
    border: 0;
    padding: 0;
  }
  .proekt__content__tags {
    margin-top: 22px;
  }
  .proekt__content__tags__item {
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #3c64b1;
  }
}
.my-performer__letter {
  margin-bottom: 18px;
  max-width: 970px;
}
.my-performer__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-bottom: 9px;
}
.my-performer__letter__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-bottom: 6px;
}
.my-performer__letter__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373f41;
}
.my-performer__answers {
  margin-bottom: 9px;
  max-width: 970px;
}
.my-performer__answers__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
  margin-bottom: 14px;
}
.my-performer__answers__item {
  margin-bottom: 9px;
  .title {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
  }
  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #79758a;
    margin-bottom: 8px;
  }
  .answer {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: 0.2px;

    color: #373f41;
    font-family: 'Mulish';
  }
}
.my-performer__stavka {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #79758a;
}
@media (max-width: 991px) {
  .proekt__performer__btn-chat {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .my-performer {
    padding: 0 10px;
  }
  .proekt__detail--my-project .proekt__content__person__name {
    font-size: 14px;
    line-height: 22px;
  }
  .my-performer__answers__item .title {
    font-size: 14px;
    line-height: 22px;
  }
  .my-performer .proekt__content__tags {
    margin: 0;
    margin-bottom: 15px;
  }
  .my-performer .proekt__content__tags__item {
    padding: 5px 12px;
  }
}
.my-proekts-list--delete {
  background: #f2f1f3;
  color: #18171c;
  border: 1px solid #d7d6dc;
  box-shadow: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 20px;
  color: #18171c;
  transition: 0.3s all;
}
.my-proekts-list--delete:hover {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 15%);
}
.my-proekts-list--delete:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.my-offers__bot__text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373f41;
  margin-bottom: 18px;
}
.my-offers__src {
  margin-bottom: 9px;
}
.my-offers__src__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
.my-offers__src__label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #79758a;
}
@media (max-width: 575px) {
  .my-offers__bot-flex {
    flex-direction: column-reverse;
    align-items: flex-start;
    .btn {
      font-size: 14px;
      line-height: 20px;
    }
    .proekt__content__actions {
      margin-top: 20px !important;
      flex: none;
    }
  }
  .my-offers__bot__title {
    font-size: 14px;
    line-height: 20px;
  }
  .my-offers__src__title {
    font-size: 14px;
    line-height: 20px;
  }
}
.choose__expert-modal__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #053ffa;
  padding-top: 50px;
  margin-bottom: 24px;
}
.choose__expert-modal__title2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  max-width: 1070px;
  margin: 0 auto;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .choose__expert-modal__title2 {
    font-size: 16px;
    line-height: 22px;
  }
  .choose__expert-modal__title {
    margin-bottom: 15px;
  }
}
.choose__expert-modal__info {
  padding-top: 31px;
  padding-bottom: 30px;
  margin: 0 -16px;
  border-top: 1px solid #a1a4b1;
  padding-right: 24px;
  padding-left: 24px;
  border-bottom: 1px solid #a1a4b1;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  a {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.015em;
    color: #2d9cdb;
    &:hover {
      text-decoration: underline;
    }
  }
}
.choose__expert-modal__bot {
  padding-top: 152px;
  padding-bottom: 125px;
  border-bottom: 1px solid #a1a4b1;
  display: flex;
  justify-content: center;
  margin: 0 -16px;
  padding-right: 24px;
  padding-left: 24px;
  button,
  a {
    margin: 0 12px;
  }
}
.proekt__detail__control {
  margin-top: 18px;
}
.proekt__detail__control__flex {
  display: flex;
  flex-wrap: wrap;
  button {
    margin-right: 24px;
    margin-bottom: 19px;
    max-width: 100%;
  }
  .btn-support {
    padding-left: 0;
    padding-right: 0;
  }
}

.proekt__content__desc {
  word-break: break-word;
  margin-bottom: 15px;
}
@media (max-width: 991px) {
  .proekt__detail__control__flex {
    flex-direction: column;
    button {
      margin-right: 0;
    }
  }
  .choose__expert-modal__title {
    font-size: 20px;
    line-height: 28px;
    padding-top: 50px;
  }
  .choose__expert-modal__bot {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
.company-status-7 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.company-status-7__item {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-right: 15px;
  margin-bottom: 19px;
  span {
    color: #26d983;
    margin-left: 35px;
    font-weight: bold;
  }
}

@media (max-width: 575px) {
  .company-status-7 {
    justify-content: center;
  }
}
.choose__expert-modal__info__row {
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
}
.change__cond {
  padding-top: 50px;
  padding-bottom: 100px;
}
.change__cond__t {
  text-align: center;
  margin-bottom: 15px;
}
.change__cond__wrap {
  display: flex;
  justify-content: center;
  margin: 0 -5px;
  margin-bottom: 50px;
  .btn {
    margin: 0 5px;
  }
}

.change__cond__actions {
  display: flex;
  justify-content: center;
  margin: 0 -15px;
  .btn {
    margin: 0 15px;
  }
}
.change__cond__form {
  max-width: 800px;
  margin: 0 auto;

  .change__cond__actions {
    margin-top: 50px;
  }
}
.change__cond__field {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  padding: 0 15px;
  .input {
    margin: 0 5px;
  }
}

.custom-datepicker {
  .react-datepicker__day--selected {
    background-color: #0f47c0;
  }
}

@media (max-width: 767px) {
  .change__cond {
    padding-top: 25px;
    padding-bottom: 50px;
  }
  .change__cond__wrap {
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    .btn {
      margin: 0;
      margin-bottom: 10px;
      max-width: 400px;
      width: 100%;
    }
  }
  .change__cond__actions {
    flex-direction: column;
    align-items: center;
    .btn {
      margin: 0;
      margin-bottom: 10px;
      max-width: 400px;
      width: 100%;
    }
  }
}
