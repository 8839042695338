.notFound {
  display: flex;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: calc(100vh - 80px);
  position: relative;
  padding-left: 100px;
  img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.notFound__left {
  max-width: 500px;
  position: relative;
  z-index: 1;
}
.notFound__title {
  font-weight: 700;
  font-size: 128px;
  line-height: 1;
  color: #262a39;
  margin-bottom: 61px;
}
.notFound__text {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #79758a;
  margin-bottom: 61px;
}

@media (min-width: 1921px) {
  .notFound {
    min-height: 800px;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media (max-width: 1449px) {
  .notFound img {
    max-width: 800px;
  }
}
@media (max-width: 1300px) {
  .notFound img {
    max-width: 700px;
  }
}
@media (max-width: 1199px) {
  .notFound {
    padding-left: 0;
    img {
      max-width: 500px;
    }
  }
}
@media (max-width: 991px) {
  .notFound {
    min-height: initial;
    padding-top: 75px;
    img {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .notFound__title {
    font-size: 100px;
    margin-bottom: 30px;
  }
  .notFound__text {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 30px;
  }
}
