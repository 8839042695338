.portfolio__list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1031px;
  margin: 0 -38px;
  padding-top: 21px;

  padding-right: 28px;
  padding-left: 28px;
}
.portfolio__list-wrap {
  min-height: 400px;
}
.portfolio__list__item {
  width: calc(100% / 3 - 76px);
  margin: 0 38px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  max-width: 248px;
}
.portfolio__list__name {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 4px;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.portfolio__list__data {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #000000;
  margin-bottom: 4px;
}
.portfolio__list__img {
  margin-bottom: 10px;
  max-width: 100%;
  padding-bottom: 57%;
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
  background-color: #e0e0e0;
}
.portfolio__list__edit {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.portfolio__list__btnLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 12px;
  background-color: #e0e0e0;
  border: 0;
  transition: 0.3s all;
  &:hover {
    background-color: #d8d7d7;
  }
}
.portfolio__list__btnRight {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  padding: 5px;
  border-radius: 100%;
  &:hover {
    background-color: #e0e0e0;
  }
}

@media (max-width: 1200px) {
  .portfolio__list {
    margin: 0 -15px;
  }
  .portfolio__list__item {
    width: calc(100% / 3 - 30px);
    margin: 0 15px;
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .portfolio__list {
    padding-right: 0;
    padding-left: 0;
  }

  .portfolio__list__item {
    width: calc(100% / 2 - 30px);
  }
}
@media (max-width: 556px) {
  .portfolio__list {
    justify-content: center;
  }
  .portfolio__list__item {
    width: calc(100% / 1 - 30px);
  }
}

.del-portfolio-modal {
  position: relative;
  padding-top: 220px;
  min-height: 680px;
  width: 1356px;
}
.del-portfolio-modal__btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: 0;
}
.del-portfolio-modal__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #053ffa;
  margin-bottom: 112px;
}
.del-portfolio-modal__btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  button {
    margin: 15px 12px;
  }
}
.upload-wrap__btns {
  .btn {
    font-size: 14px;
    padding: 6px 20px;
    line-height: 20px;
  }
}

@media (min-width: 991px) {
  .upload-wrap__btns {
    padding-left: 27px;
  }
}
.addPortfilio__btn {
  margin: 0 5px;
}
@media (max-width: 768px) {
  .del-portfolio-modal {
    padding: 64px 37px;
    min-height: initial;
    width: 342px;
  }
  .del-portfolio-modal__title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 48px;
  }
  .del-portfolio-modal__overlay {
    .modal-body {
      padding: 0;
    }
  }
  .del-portfolio-modal__btn {
    top: 10px;
    right: 10px;
  }
  .del-portfolio-modal__btns {
    flex-direction: column;
    .btn {
      margin: 0;
      margin-bottom: 18px;
      padding: 0.45rem 1.1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 359px) {
  .del-portfolio-modal {
    padding: 64px 30px;
  }
}
