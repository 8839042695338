.admin-wrap {
  padding-top: 25px;
  padding-bottom: 25px;
}
.m_app--admin {
  height: 100%;
  overflow: auto;
  .m_header {
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
  }
}
.m_app.m_app--login {
  padding-top: 0;
}
.m_header li {
  position: relative;
}
.header__menu__sub {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: max-content;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
  border-radius: 2px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 5px;
  }
}
.m_header li:hover .header__menu__sub {
  display: block;
}
