.status.status--edit {
  max-width: initial;
  margin-bottom: 16px;
  padding-left: 0;
}
.exp-profile {
  width: 100%;
}
.exp-profile-modal {
  width: 1352px;
  max-width: calc(100% + 16px + 16px);
  margin: -16px;
}
.exp-profile__top {
  height: 344px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .about__sett__left {
    position: absolute;
    left: 24px;
    bottom: 16px;
    padding: 8px;
    padding-right: 12px;
    background-color: #fff;
  }
}
.exp-profile__avatar {
  width: 230px;
  height: 230px;
  border-radius: 100%;
  border: 6px solid #f1f6fa;
  background-color: #f1f6fa;
  left: 50%;
  bottom: 0;
  background-size: cover;
  background-position: center;
  position: absolute;
  transform: translate(-50%, 39px);
}
.exp-profile__avatar--topExpert {
  border: 6px solid #0f47c0;
}
.profile__topExpert {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #0f47c0;
  position: relative;
  width: 230px;
  margin: 0 auto;
  text-align: center;
  svg {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }
}
.exp-profile__info {
  display: flex;
  justify-content: center;
  margin-top: 18px;
  align-items: flex-start;
  margin-bottom: 19px;
}
.exp-profile__rate {
  background: #092c9e;
  border-radius: 2px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #f1f6fa;
  padding-left: 8px;
  padding-right: 31px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 31px;
  }
}
.exp-profile__rate.tooltips-wrap {
  .tooltips {
    left: 0;
    transform: translateX(0);
    width: 450px;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Mulish';
    font-weight: 300;
    bottom: 120%;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      height: 16px;
      width: 100%;
      left: 0;
    }
    a {
      display: inline-block;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 991px) {
  .exp-profile__rate.tooltips-wrap {
    .tooltips {
      width: 250px;
    }
  }
}
.exp-profile__rate.tooltips-wrap.active {
  .tooltips {
    display: block;
  }
}
.exp-profile__col {
  flex: 1;
  display: flex;
}
.exp-profile__person {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 15px;
  padding-left: 15px;
}
.exp-profile__person__name {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #0a243f;
  text-align: center;
  margin-bottom: 8px;
}
.exp-profile__person__location {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #737b7d;
  text-align: center;
}
.exp-profile__person__prof {
  text-align: center;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.8);
}
.exp-profile__person__label {
  display: flex;
  align-items: flex-start;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #0a243f;
  margin-bottom: 17px;
  img {
    margin-right: 18px;
  }
}
.exp-profile__person__rate {
  display: flex;
  justify-content: center;
  color: #0f47c0;
  margin-bottom: 25px;
  svg {
    margin-right: 6px;
    margin-left: 6px;
  }
}
.exp-profile__person__label-wrap {
  margin-top: 5px;
}
.exp-profile__col--right {
  justify-content: flex-end;
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
    img {
      margin-right: 8px;
    }
  }
}
.exp-profile__tabs__content {
  display: none;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
}
.exp-profile__tabs__content.active {
  display: block;
}
.exp-profile__tabs__nav {
  display: flex;
  margin: 0;
  padding: 0;
  li,
  a,
  button {
    max-width: 466px;
    padding: 22px 15px 15px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    color: #0a243f;
    list-style: none;
    flex: 1;
    cursor: pointer;
    background-color: transparent;
    border: 0;
  }
  li:hover,
  a:hover,
  button:hover {
    color: #092c9e;
  }
  li.active,
  a.active,
  button.active {
    background-color: #fff;
    color: #092c9e;
  }
}
.exp-profile__row {
  padding-top: 39px;
  margin-bottom: 20px;
  padding-bottom: 39px;
  padding-right: 60px;
  padding-left: 30px;
  border-bottom: 1px solid #a1a4b1;
  .exp-content__title {
    padding-left: 0;
    border-bottom: 0;
    h3 {
      margin-bottom: 24px;
    }
  }
}
.exp-profile__row:last-child {
  border-bottom: 0;
}
.exp-profile__skills {
  display: flex;
  flex-wrap: wrap;
  max-width: 1040px;
}
.exp-profile__slills__item {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #3c64b1;
  background: #dbeefb;
  border-radius: 20px;
  margin-right: 14px;
  margin-bottom: 12px;
}
.exp-profile__bot-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.exp-profile__bot-btn--center {
  justify-content: center;
  margin-top: 56px;
}
.exp-profile__uslugi__wrap {
  display: flex;
}
.exp-profile__uslugi__img {
  margin-right: 78px;
  flex: 0 0 277px;
  width: 277px;
  height: 185px;
  background-size: cover;
  background-position: center;
}

.exp-profile__uslugi__link {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #18a0fb;
  margin-bottom: 12px;
  display: inline-block;
}

.exp-profile__uslugi__tags {
  display: flex;
  flex-wrap: wrap;
}
.exp-profile__uslugi__tags__item {
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #737b7d;
  margin-right: 70px;
  margin-bottom: 12px;
}
.exp-profile__uslugi__text {
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}
.exp-profile__uslugi__wrap2 {
  max-width: 701px;
}

.skills__list {
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin-bottom: 6px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    span {
      width: 175px;
      max-width: 100%;
      margin-right: 38px;
      word-break: break-all;
    }
  }
}
.skills__text p {
  font-size: 15px;
  line-height: 24px;
  color: #000000;
  margin: 10px 0;
  max-width: 826px;
}
.exp-profile__portfolio {
  margin-bottom: 30px;
}
.exp-profile__portfolio__top {
  display: flex;
  margin-bottom: 38px;
  a {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.8);
    margin-right: 82px;
  }
  a:hover {
    color: #2d9cdb;
  }
  a.active {
    color: #2d9cdb;
  }
  button {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.8);
    margin-right: 82px;
  }
  button:hover {
    color: #2d9cdb;
  }
  button.active {
    color: #2d9cdb;
  }
}

.exp-profile__portfolio__wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -36px;
  margin-bottom: -97px;
}
.exp-profile__portfolio__item {
  margin: 0 36px;
  width: calc(100% / 4 - 72px);
  padding-bottom: 13%;
  background-size: cover;
  background-position: center;
  margin-bottom: 97px;
}

.exp-profile__reviews {
  max-width: 944px;
  margin: 0 auto;
  margin-bottom: 20px;
}
.exp-profile__reviews__item {
  padding-bottom: 6px;
  margin-bottom: 26px;
  border-bottom: 1px solid #a1a4b1;
}

.exp-profile__reviews__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
}
.exp-profile__reviews__name {
  font-family: 'Mulish';
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
}
.exp-profile__reviews__price {
  margin-left: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.3px;
  color: #a3a3a3;
  span {
    margin-left: 26px;
  }
}
.exp-profile__reviews__rate {
  margin-bottom: 19px;
  svg {
    color: #092c9e;
    margin-right: 8px;
  }
}
.exp-profile__reviews__text {
  font-family: 'Mulish';
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373f41;
  margin-bottom: 18px;
}
.exp-profile__reviews__author {
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
}
.exp-profile__reviews__author:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.8);
}
.exp-profile__uslugi__list {
  padding-bottom: 45px;
  .exp-profile__uslugi {
    padding-top: 40px;
    padding-bottom: 64px;
    border-bottom: 1px solid #a1a4b1;
  }
}
.exp-profile__reviews-all {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 1199px) {
  .exp-profile__portfolio__item {
    width: calc(100% / 3 - 40px);
    padding-bottom: 18%;
    margin: 0 20px;
    margin-bottom: 50px;
  }
  .exp-profile__portfolio__wrap {
    margin-bottom: -50px;
  }
  .exp-profile__uslugi__img {
    margin-right: 25px;
  }
  .exp-profile__row {
    padding-right: 30px;
  }
}
@media (max-width: 991px) {
  .exp-profile__info {
    flex-direction: column;
    align-items: center;
  }
  .exp-profile__top .about__sett__left {
    top: 24px;
    bottom: auto;
  }
  .exp-profile__info {
    margin-top: 45px;
  }
}
@media (max-width: 767px) {
  .exp-profile__tabs__nav li,
  .exp-profile__tabs__nav a {
    padding: 15px;
    white-space: nowrap;
    font-size: 16px;
  }
  .exp-profile__tabs__nav {
    overflow: auto;
  }
  .exp-profile__tabs__content {
    padding-left: 0;
    padding-right: 0;
  }
  .exp-profile__uslugi__wrap {
    flex-direction: column;
  }
  .exp-profile__uslugi__img {
    margin-right: 0;
    margin-bottom: 25px;
    max-width: 100%;
    flex: auto;
  }
  .exp-profile__portfolio__item {
    width: calc(100% / 2 - 40px);
    padding-bottom: 29%;
  }
  .exp-profile__reviews__price {
    text-align: right;
    font-size: 14px;
  }
  .exp-profile__slills__item {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 16px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .exp-profile__row {
    padding-right: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
  }
  .exp-profile__portfolio__top button {
    font-size: 18px;
    margin-right: 20px;
    padding: 0 5px;
  }
  .exp-profile__portfolio__wrap {
    margin-right: -10px;
    margin-left: -10px;
  }
  .exp-profile__portfolio__item {
    margin: 0 10px;
    width: calc(100% / 2 - 20px);
    margin-bottom: 10px;
  }
  .exp-profile__top .about__sett__left {
    left: 15px;
  }
  .exp-profile__uslugi__list .exp-profile__uslugi {
    padding-top: 15px;
  }
}
.prof__wrap {
  padding-right: 27px;
  padding-left: 27px;
}
.prof__wrap2 {
  position: relative;
  .exp-profile__tabs__content {
    max-width: 1050px;
    margin-left: auto;
    padding: 0;
  }
}
.exp-modal-prof {
  .modal-dialog {
    max-width: 1356px;
  }
  .modal-content {
    padding-bottom: 100px;
  }
}
.prof__nav {
  padding: 0;
  list-style: none;
  position: sticky;
  top: 0;
  width: max-content;
  height: 0;
  li {
    margin-bottom: 15px;
    max-width: 250px;
  }
  a {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.8);
  }
  a:hover {
    color: #0f47c0;
  }
}
.prof__wrap2 {
  .exp-profile__row {
    padding-top: 0;
    border-bottom: 0;
  }
  .exp-profile__reviews {
    margin: 0;
    max-width: initial;
  }
}

@media (min-width: 1200px) {
  .prof__wrap2 {
    .exp-profile__portfolio__item {
      width: calc(100% / 3 - 72px);
      padding-bottom: 18%;
    }
  }
}
.exp-profile__col__exp-prof {
  flex-direction: column;
  align-items: flex-end;
}

.exp-prof__action {
  display: flex;
  margin-bottom: 86px;
  .proekt__actios {
    margin-left: 0;
  }
  .btn {
    margin-left: 12px;
  }
}

@media (max-width: 1399px) {
  .prof__wrap {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 1350px) {
  .exp-prof__action {
    flex-direction: column-reverse;
    align-items: flex-end;
    margin-bottom: 20px;
    .btn {
      margin-bottom: 15px;
    }
  }
  .prof__nav {
    display: none;
  }
  .prof__wrap2 .exp-profile__tabs__content {
    margin: 0 auto;
  }
}
@media (max-width: 1199px) {
}
@media (max-width: 991px) {
  .exp-prof__action {
    flex-direction: row;
    align-items: center;
    .btn {
      margin-bottom: 0;
    }
  }
  .exp-profile__col__exp-prof {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .exp-profile__col__exp-prof {
    margin-bottom: 20px;
  }
  .exp-prof__action {
    margin-top: 15px;
    flex-direction: column-reverse;
    .proekt__actios {
      margin-top: 0;
      button {
        margin: 0 7px;
      }
    }
    .btn {
      margin-bottom: 15px;
      margin-left: 0;
    }
  }
  .prof__wrap {
    padding-right: 0;
    padding-left: 0;
  }
  .exp-modal-prof .modal-content {
    padding-bottom: 50px;
  }
}

.exp-modal-prof__top {
  display: flex;
  justify-content: flex-end;
  button {
    border: none;
    background-color: transparent;
    padding: 14px;
    transition: 0.1s all;
  }
  button:hover {
    transform: scale(1.05);
  }
}

.pinfile {
  display: flex;
  flex-wrap: wrap;
}
.pinfile__item {
  width: 150px;
  padding: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 15px;
  transition: 0.3s all;
  color: #000;

  img {
    max-width: 100%;
    display: block;
    margin-bottom: 10px;
  }
  span {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 100%;
  }
}
.pinfile__item:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.pinfile__item.pinfile__item--pdf {
  width: 100px;
  padding: 5px;
  margin: 5px;
  font-size: 11px;
  border: 0;
  img {
    margin-bottom: 5px;
    width: 40px;
    height: auto;
  }
}
@media (max-width: 500px) {
  .pinfile {
    margin: 0 -15px;
  }
  .pinfile__item {
    width: calc(50% - 14px);
    margin: 0 7px;
    margin-bottom: 15px;
  }
}

.portfolio-p {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.portfolio-p__item {
  width: calc(100% / 4 - 20px);
  margin: 0 10px;
  margin-bottom: 25px;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 10px 15px;
  transition: 0.3s all;
  cursor: pointer;
}
.portfolio-p__item:hover {
  transform: scale(1.03);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.portfolio-p__title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.portfolio-p__img {
  display: block;
  width: 100%;
  background-size: cover;
  background-position: top center;
  padding-bottom: 100%;
}

@media (max-width: 1199px) {
  .portfolio-p__item {
    width: calc(100% / 3 - 20px);
  }
}

@media (max-width: 991px) {
  .portfolio-p__item {
    width: calc(100% / 2 - 20px);
  }
  .portfolio-p__title {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .portfolio-p__item {
    width: calc(100% / 1 - 20px);
  }
  .portfolio-p__item:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .exp-profile {
    .portfolio-p__item {
      width: calc(100% / 3 - 20px);
    }
  }
}

.exp-profile-portfolio__img {
  max-width: 100%;
  display: block;
  margin-top: 25px;
}

.profile__spec__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #092c9e;
  margin-bottom: 12px;
}
.profile__spec__title2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
  margin-bottom: 8px;
}
.profile__spec__level3 {
  background: #eaf6ff;
  border-radius: 15px;
  margin-bottom: 18px;
  padding: 4px 14px;
}
.profile__spec__level3__item {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #092c9e;
}
.profile__spec {
  max-width: 743px;
}

@media (min-width: 768px) {
  .doneList {
    margin-right: -15px;
  }
}
.doneList__row {
  padding-left: 65px;
  padding-bottom: 65px;
  padding-right: 65px;
  padding-top: 50px;
  border-bottom: 1px solid #a1a4b1;
}
.doneList__item {
  max-width: 1006px;
  .proekt__tags {
    margin-bottom: 0;
  }
  .proekt__rating {
    margin-bottom: 13px;
  }
  .proekt__location {
    margin-bottom: 10px;
  }
  .proekt__location svg {
    fill: #0f47c0;
  }
}
.doneList__des {
  margin-bottom: 12px;
  max-width: 920px;
}
.doneList__top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
}
.doneList__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  max-width: 700px;
  margin-right: 15px;
}
.doneList__data {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}
.doneList__wrap {
  padding-bottom: 87px;
}

@media (max-width: 767px) {
  .doneList__row {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 545px) {
  .doneList__top {
    flex-direction: column;
  }
  .doneList__name {
    margin-right: 0;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 26px;
  }
  .doneList__row {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .doneList__des {
    font-size: 12px;
  }
}
