.my-offers__bot {
  padding-top: 71px;
  position: relative;
  padding-left: 17px;
  &:after {
    content: '';
    position: absolute;
    max-width: 920px;
    width: 100%;
    top: 45px;
    right: 0;
    display: block;
    border-top: 1px solid #e0e4ef;
  }
}
.my-offers__bot__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 6px;
}
.my-offers__bot__text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #373f41;
  margin-bottom: 18px;
}
.my-offers__src {
  margin-bottom: 9px;
}
.my-offers__src__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: rgba(0, 0, 0, 0.8);
}
.my-offers__src__label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #79758a;
}
.my-offers__stavka__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #79758a;
}
.my-offers__bot-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 27px;
  .proekt__content__actions {
    flex: none;
  }
}
.my-offers__detail {
  padding-top: 22px;
  border-top: 1px solid #e0e4ef;
}
@media (max-width: 991px) {
  .my-offers__bot {
    padding-top: 30px;
    padding-left: 0;
    &:after {
      top: 17px;
    }
  }
}

@media (max-width: 575px) {
  .my-offers__bot-flex {
    flex-direction: column-reverse;
    align-items: flex-start;
    .btn {
      font-size: 14px;
      line-height: 20px;
    }
    .proekt__content__actions {
      margin-top: 20px !important;
      flex: none;
    }
  }
  .my-offers__bot__title {
    font-size: 14px;
    line-height: 20px;
  }
  .my-offers__src__title {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (min-width: 1200px) {
  .proekt__tabs--offers li {
    margin-right: 25px;
  }
}

.proekt__list__item.disabled {
  .proekt__content__person__name {
    color: #615e6e;
  }
  .proekt__tags__item {
    color: #615e6e;
  }
  .proekt__rating {
    color: #615e6e;
  }
  .proekt__rating__count {
    color: #615e6e;
  }
  .proekt__content__tags__item {
    color: #79758a;
    background: #d7d6dc;
  }
  .proekt__location {
    color: #615e6e;
  }
  .proekt__location svg {
    opacity: 0.3;
  }
  .proekt__content__avatar {
    opacity: 0.3;
  }
}
.proekt__content__right--invations {
  display: flex;
  align-items: center;
  margin: 0 -5px;
  margin-bottom: 15px;
  .btn {
    margin: 0 5px;
  }
}
