.add-skills {
  max-width: 376px;
  margin-bottom: 44px;
}
.search-input {
  margin-bottom: 12px;
  input {
    width: 100%;
    height: 48px;
    padding-left: 50px;
    margin-bottom: 0;
  }
  .search-input__input {
    > button {
      top: 50%;
      z-index: 2;
      left: 13px;
      transform: translateY(-50%);
      position: absolute;
      background-color: transparent;
      border: 0;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      padding: 0;
    }
    button:hover {
      background-color: #f1f6fa;
    }
  }
}

.add-skills__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.add-skills__tag {
  padding: 4px 10px;
  background: #dbeefb;
  border-radius: 40px;
  font-weight: bold;
  font-size: 10px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  color: #3c64b1;
  margin-right: 7px;
  margin-bottom: 10px;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;
  }
}

.exp-content__skills {
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .exp-content__skills {
    margin-top: 25px;
    margin-bottom: 50px;
  }
}
.search-input {
  position: relative;
}
.search-input__input {
  position: relative;
}
.search-input__input input:focus {
  border: 1px solid #2b5cfb;
  box-shadow: 0px 1px 3px rgb(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px;
  outline: none;
}
.search-input__list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow: auto;
  z-index: 3;
  background-color: #fff;
  border: 1px solid #2b5cfb;
  box-shadow: 0px 1px 3px rgb(0, 0, 0, 0.15);
  border-radius: 0px 0px 4px 4px;
  margin: 0;
  padding: 0;
  display: none;
}
.search-input.focus {
  .search-input__list {
    display: block;
  }
}
.search-input__list li a {
  font-size: 14px;
  line-height: 24px;
  color: #8b90a0;
  margin: 0;
  padding: 0;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  display: block;
}
.search-input__list li {
  list-style: none;
}
.search-input__list li a:hover {
  color: #092c9e;
  background-color: rgba(0, 0, 0, 0.05);
}

.exp-content__wrap__addPortfilio {
  display: flex;
  justify-content: center;
  max-width: initial;
  width: 100%;
  padding-top: 45px;
  margin-bottom: 85px;
  .btn {
    font-size: 20px;
    line-height: 32px;
    padding: 6px 20px;
  }
}

.exp-modal {
  width: 1038px;
  max-width: 100%;
  .modal-dialog {
    max-width: 1062px;
  }
  .modal-content {
    padding: 12px;
  }
  .modal-header {
    background: #0f47c0;
    border-radius: 20px 20px 0px 0px;
    padding: 12px 27px;
    margin-bottom: 18px;
  }
  .modal-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #f4f7ff;
  }
  .exp-modal__addProect {
    background: #f1f6fa;
    padding: 50px 31px 50px;
    .upload-wrap__btns button {
      margin-right: 18px;
    }
  }
  .exp-modal__addProect__wrap {
    max-width: 764px;
    .label {
      font-size: 14px;
      line-height: 20px;
      color: #494653;
    }
    // .upload-wrap {
    //   justify-content: flex-end;
    // }
  }
}
label.upload-wrap__col {
  position: relative;
  input {
    display: flex;
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }
}

label.upload-wrap__col:hover {
  color: #0a58ca;
}
.exp-modal-footer {
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 27px;
  padding-right: 27px;
  .upload-wrap__btns button {
    margin-right: 18px;
  }
  .btn {
    font-size: 14px;
    line-height: 20px;
  }
  .btn-outline-primary {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.15);
  }
}

@media (max-width: 991px) {
  .exp-modal .exp-modal__addProect__wrap .upload-wrap {
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .exp-modal .exp-modal__addProect__wrap .upload-wrap {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
@media (max-width: 550px) {
  .exp-modal-footer {
    padding-left: 0;
    padding-right: 0;
  }
  .exp-modal .exp-modal__addProect {
    padding: 40px 10px 50px;
  }
  .exp-content__wrap__addPortfilio .btn {
    font-size: 16px;
  }

  .exp-modal .modal-title {
    font-size: 16px;
    line-height: 24px;
  }
}
.exp-content__title--portfolio {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  h3 {
    margin-right: 15px;
  }
  .btn {
    margin-bottom: 10px;
  }
}
