.exp-content__wrap--spec {
  max-width: 801px;
  &:last-child {
    margin-bottom: 21px;
  }
  .spec__level1 {
    display: flex;
    align-items: center;
    .react-select {
      margin-right: 22px;
      flex: 1;
      max-width: initial;
      width: 100%;
      margin-bottom: 0;
    }
    select:disabled {
      background-image: none;
    }
    button {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      padding: 7px 20px;
      background: #092c9e;
    }
  }
  .spec__level2 {
    .react-select {
      max-width: 604px;
      margin-bottom: 30px;
    }
  }
  .spec__level3__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #0a243f;
  }
  .spec__level3 {
    .react-select {
      max-width: 100%;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .spec__level3__block {
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .spec__level3__wrap {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .exp-content__edit--spec {
    .exp-content__title h3 {
      font-size: 24px;
      line-height: 30px;
    }
    .exp-content__wrap--spec {
      .spec__level1 {
        flex-direction: column;
        align-items: flex-end;
        .react-select {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }
      .spec__level3__block {
        margin-bottom: 30px;
      }
    }
  }
}
.spec-modal {
  .modal-header {
    position: relative;
    padding-right: 55px;
  }
  .modal-content {
    border-radius: 20px 20px 0px 0px;
  }
  .modal-body {
    padding: 12px;
  }
}
.spec-modal__close {
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 15px;
  right: 15px;
}
.spec-modal-body {
  background: #f1f6fa;
  padding: 30px 15px;
  padding-bottom: 65px;
  margin-bottom: 25px;
}
.spec-modal__sub {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #494653;
  text-align: center;
  margin-bottom: 75px;
}
.spec-modal__btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
  .btn {
    margin: 0 12px;
    margin-bottom: 15px;
  }
}
.spec-modal__bottom {
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  a {
    text-decoration: underline;
  }
}
@media (max-width: 767px) {
  .spec-modal .exp-modal {
    width: 342px;
  }
  .spec-modal__btns {
    flex-direction: column;
    .btn {
      margin: 0;
      margin-bottom: 18px;
      padding: 0.45rem 1.1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .spec-modal__sub {
    margin-bottom: 50px;
  }
}
.spec-selectors {
  .react-select .react-select__control {
    height: 48px;
  }
}
