.base {
  .header {
    height: 70px;
  }
  .header__lang__name {
    color: #000;
  }

  .wrap {
    max-width: 970px;
  }
  .prpb__breadcrumb {
    margin-left: 0;
  }
  .top-search {
    margin-left: 0;
  }
  .header__lang__m_btn svg {
    stroke: #000;
  }
  .exp-content__edit {
    width: 100%;
    min-height: 400px;
  }
  .proekt__search {
    max-width: 100%;
  }
  .exp-content--company {
    margin-top: 0;
  }
  .category-item {
    display: flex;
    height: auto;
    min-height: auto;
    border-bottom: 1px solid #d8d8d8;
    padding: 15px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transition: all 0.2s ease;
      background: #f9f9f9;
    }
  }
  .category-item__title {
    font-size: 15px;
    line-height: 24px;
    color: #092c9e;
    text-align: left;
    font-weight: bold;
    width: 100%;
  }
  .category-content {
    width: calc(100% - 180px);
    padding: 0;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    h4 {
      width: 100%;
      line-height: 24px;
    }
    p {
      font-size: 14px;
      color: #596378;
      line-height: 25px;
      font-weight: 500;
      width: 100%;
      margin-bottom: 0;
    }
  }
  .author-block {
    width: 180px;
    height: 30px;
    display: flex;
    color: #717a84;
    font-size: 13px;
    align-items: center;
    justify-content: flex-start;
  }
  .author-block .image-wrapper {
    width: 30px;
    height: 30px;
    min-width: 30px;
    margin-right: 7px;
  }
  .author-block .author-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .author-block .text-wrapper {
    width: 163px;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .author-block .author-name {
    color: #3b444f;
  }
  .base__wrap {
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    margin: 15px 0;
  }
}
@media (max-width: 767px) {
  .base .category-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
  }
  .base .category-content {
    width: 100%;
  }
}
.base__detail {
  max-width: 750px;
}
.base__detail--full {
  max-width: 100%;
}
