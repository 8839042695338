.cropContainer {
  width: 300px;
  height: 300px;
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  margin-top: 15px;
}
.cropRange {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.exp-modal__file {
  .cropContainer,
  .cropRange {
    display: none;
  }
}

.exp-modal__file.upload {
  .cropContainer {
    display: block;
  }
  .cropRange {
    display: flex;
  }
}
