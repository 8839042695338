.admin_project_detail {
  display: flex;
}

.input_project {
  width: auto;
}
.admin-chat {
  .chatDetail__body {
    max-height: 600px;
    overflow: auto;
  }
}

.admin-chat__flex {
  padding-right: 100px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  display: flex;
  border-bottom: 1px solid #a3a3a3;
}
.admin-chat__item {
  margin-right: 50px;
  color: #000;
  font-weight: bold;
  img {
    overflow: hidden;
    border-radius: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  a {
    text-decoration: underline;
    color: #000;
    font-weight: 400;
  }
}
.admin-edit-review {
  .not-auth__title {
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .textarea {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 50px;

    textarea {
      height: 150px;
    }
  }
}
