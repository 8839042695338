.exp-content--company {
  margin-top: 60px;
}
@media (max-width: 1024px) {
  .exp-content--company {
    margin-top: 0;
  }
}
.exp-content__wrap--employer {
  max-width: 684px;
  margin-bottom: 75px;
}
.label--add-employer {
  position: relative;
  display: block;
  font-size: 14px;
  button {
    position: absolute;
    right: 0;
    transform: translate(100%, -50%);
    top: 50%;
    background-color: transparent;
    border: 0;
    &:hover {
      svg {
        transition: 0.3s all;
        transform: scale(1.05);
      }
    }
  }
}
.button--add-employer {
  margin-top: 30px;
  margin-bottom: -5px;
}
.button--add-employer button {
  border: 0;
  background-color: transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.8);
  &:hover {
    color: #000;
  }
}

.button--add-employer--sfera {
  margin-top: 0px;
  margin-bottom: 24px;
}
.exp-content__wrap--sfera {
  .react-select {
    max-width: 494px;
    margin-bottom: 25px;
  }
}
.exp-content__wrap--sfera__selects {
  .react-select:last-child {
    margin-bottom: 20px;
  }
}
.sfera__limit {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #022964;
  margin-bottom: 24px;
  a {
    text-decoration: underline;
  }
}

.sfera__label {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #092c9e;
  display: block;
  margin-bottom: 44px;
}

.profile-employers {
  margin-top: 45px;
  padding-bottom: 45px;
  .exp-content__title {
    border-bottom: 1px solid #a1a4b1;

    h3 {
      margin-bottom: 8px;
    }
  }
}
.profile-employers__list {
  padding-top: 15px;
}
.profile-employers__item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #000000;
  cursor: pointer;
}
.profile-employers__img {
  width: 48px;
  height: 48px;
  background-size: contain;
  margin-right: 13px;
}

.done-work-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .exp-profile__bot-btn {
    margin-top: 0;
  }
}
.done-work-flex-left {
  display: flex;
}
.done-work {
  padding-left: 15px;
  padding-right: 0;
  margin-right: -15px;
  .exp-content__title {
    border-bottom: 1px solid #a1a4b1;
    padding-right: 15px;
    padding-left: 15px;
    h3 {
      margin-bottom: 15px;
    }
  }
}
.prof__wrap2 .exp-profile__row.done-work-empty {
  border-bottom: 1px solid #a1a4b1;
  padding-bottom: 10px;
}
.done-work__item {
  padding-top: 21px;
  padding-bottom: 14px;
  border-bottom: 1px solid #a1a4b1;
  padding-right: 90px;
  .proekt__content__person {
    justify-content: space-between;
    align-items: flex-start;
    .proekt__actios {
      margin-top: 0;
    }
  }
  .proekt__content__person__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.1px;

    color: #000000;
  }
  .proekt__content__text {
    max-width: 920px;
  }
  .proekt__content__top {
    max-width: 920px;
  }
  .proekt__content__tags__item {
    border-radius: 6px;
  }
}
.done-work {
  padding-top: 40px;
  padding-bottom: 117px;
}
@media (min-width: 768px) {
  .done-work {
    .exp-content__title {
      padding-left: 29px;
    }
  }
  .done-work__item {
    padding-left: 29px;
  }
}

.exp-modal-prof--company {
  .exp-profile__col--right {
    justify-content: center;
  }
}

.company-modal-content {
  margin-left: auto;
  .profile-employers .exp-content__title {
    max-width: initial;
    border-bottom: 0;
  }
  .profile-employers {
    margin: 0;
    padding: 0;
  }
  .exp-profile__row {
    border-bottom: 1px solid #a1a4b1;
    padding-bottom: 15px;
    padding-top: 40px;
  }
  .exp-profile__row:last-child {
    border-bottom: 0;
  }
  .done-work {
    padding: 0;
    margin: 0;
    padding-right: 60px;
    padding-left: 30px;
    padding-top: 60px;
    border-top: 1px solid #a1a4b1;
  }
  .done-work .exp-content__title {
    border: 0;
    padding: 0;
  }
  .done-work__item:last-child {
    border-bottom: 0;
  }
}
.company-modal-content .exp-profile__row.done-work-empty {
  padding-bottom: 10px;
  margin-bottom: 9px;
}
.done-work-empty .exp-content__title h3 {
  margin-bottom: 0;
}
.done-work-empty__text {
  padding-left: 35px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  letter-spacing: 0.1px;
  color: #000000;
  margin-bottom: 50px;
  padding-right: 15px;
}
.company-modal-content .exp-profile__row.profile-sfera--row {
  border-bottom: 0;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .done-work-empty__text {
    padding-left: 15px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .done-work {
    padding-top: 25px;
  }
  .done-work-flex {
    flex-direction: column;
    align-items: flex-start;
    .done-work-flex-left {
      margin-bottom: 15px;
    }
    .exp-profile__bot-btn {
      margin-left: auto;
    }
  }
  .done-work__item {
    padding-right: 0;
  }
  .done-work {
    margin: 0;
    padding-right: 15px;
    padding-left: 15px;
    .proekt__label {
      margin-right: 15px;
      font-size: 12px;
    }
  }
  .sfera__label {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .done-work .exp-content__title {
    padding-right: 0;
    padding-left: 0;
  }
  .company-modal-content .done-work {
    padding-right: 15px;
    padding-left: 15px;
  }
  .company-modal-content .exp-profile__row {
    padding-top: 15px;
  }
}

.puclic-project {
  display: flex;
  justify-content: center;
  padding-top: 33px;
  padding-bottom: 41px;
}

.now-proekt__empty--public {
  font-size: 15px;
  padding-top: 25px;
}

.now-proekt__empty--public__wrap {
  display: flex;
  justify-content: center;
  margin-top: 38px;
}

@media (max-width: 991px) {
  .now-proekt__empty--public {
    padding-bottom: 35px;
  }
  .now-proekt__empty--public__wrap {
    margin-top: 25px;
  }
}

.multi-selectize {
  .selectize-control {
    min-height: 48px;
  }
  .selectize-input {
    border-radius: 15px;
    min-height: 48px;
  }
  .selectize-control.multi .selectize-input [data-value] {
    color: #053ffa;
    background: #dbeefb;
    border-radius: 15px;
    padding: 4px 10px;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    font-family: 'Mulish';
    border: 0;
    min-width: max-content;
  }
  .selectize-control.multi .selectize-input.has-items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .selectize-control.plugin-remove_button .item .remove {
    border-left: 0;
    border-radius: 100%;
    margin-right: 5px;
  }
}
