.admin_search {
  max-width: 100%;
}

.admin_knowledge_header {
  display: flex;
  padding-bottom: 25px;
  font-size: 42px;
}

.knowledge_table_warp {
  padding-top: 25px;
  .code {
    max-width: 500px;
    max-height: 100px;
    overflow: auto;
  }
  .admin_knowledge_action_btn button {
    font-size: 14px;
    margin-left: 5px;
  }
}

.admin_input_label {
  font-size: 24px;
  padding-bottom: 5px;
}
.baza__create__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
.baza__create__row {
  margin-bottom: 20px;
}
