.user_table_warp {
  padding-top: 25px;

  .admin_user_action_btn button {
    font-size: 14px;
    margin-left: 5px;
  }
}
.proekt__tabs--admin {
  margin-top: 0;
}
.admin-user__contacts {
  margin-bottom: 20px;
  display: flex;
  & > div {
    margin-right: 10px;
  }
}

.admin-user-detail {
  display: flex;
}
.admin-user-detail__content {
  width: calc(100% - 250px);
  padding: 12px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.admin-user-detail__nav {
  flex: 0 0 250px;
  padding-left: 0;
  li {
    list-style: none;
    margin-bottom: 5px;
  }
  a {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #afacb9;
    padding-top: 2px;
    padding-bottom: 2px;
    &.active {
      color: #092c9e;
    }
  }
}

.admin-info-main {
  margin-top: 20px;
}
.custom-modal-big--admin {
  min-height: initial;
  width: 800px;
  .not-auth__title {
    padding-top: 100px;
    font-size: 28px;
    line-height: 32px;
  }
}
.complited.complited--admin {
  justify-content: flex-start;
  .complited__name {
    flex: 1;
    max-width: initial;
  }
}
