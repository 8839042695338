.status {
  max-width: 1062px;
  margin-left: auto;
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #0a243f;
  margin-bottom: 37px;
}
.about__sett__text {
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.8);
}
@media (max-width: 1450px) {
  .status {
    max-width: 955px;
  }
}
@media (max-width: 1400px) {
  .status {
    max-width: 975px;
  }
}
.status__left {
  display: flex;
  align-items: center;
}
.status__type {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #0f47c0;
  .status__type__code {
    padding: 3px;
    margin-right: 5px;
    color: #fff;
    font-weight: bold;
    background-color: #0f47c0;
    display: block;
    font-size: 12px;
    line-height: 1;
    border-radius: 2px;
  }
  img {
    margin-right: 2px;
  }
}
.status__right {
  display: flex;
  align-items: center;
  .form-switch {
    margin-left: 20px;
  }
}
.form-check-label,
.form-check-input {
  cursor: pointer;
}
.status__left {
  .tooltips-wrap {
    .tooltips {
      font-family: 'Open Sans', sans-serif;
      width: 350px;
      bottom: calc(100% + 0px);
      left: auto;
      transform: translateX(30px);
      right: 100%;
      font-size: 14px;
      font-weight: 400;
      padding: 5px 8px;
      letter-spacing: 0;
      a {
        text-decoration: underline;
        color: #9abdff;
        font-weight: 700;
      }
      a:hover {
        opacity: 0.9;
      }
    }
    .tooltips:before {
      left: auto;
      right: 15px;
    }
    .tooltips:after {
      content: '';
      left: 100%;
      position: absolute;
      height: 100%;
      width: 100px;
      display: block;
      top: 0;
    }
  }
}
@media (max-width: 1350px) {
  .status__left {
    .tooltips-wrap {
      .tooltips {
        right: auto;
        left: 0;
        width: 250px;
        transform: translate(0);
      }
      .tooltips:before {
        left: 15px;
        right: auto;
      }
      .tooltips:after {
        display: none;
      }
    }
  }
}
@media (max-width: 480px) {
  .status__left {
    .tooltips-wrap {
      .tooltips {
        right: 0;
        left: auto;
        top: 100%;
        bottom: auto;
        width: 250px;
      }
      .tooltips:before {
        right: 15px;
        left: auto;
        bottom: auto;
        top: -8px;
      }
    }
  }
}
.form-switch {
  padding-left: 3em;
  .form-check-input {
    width: 2.5em;
    margin-left: -3em;
    margin-top: 0.15em;
  }
  .form-check-input:checked {
    background-color: #0f47c0;
    border-color: #0f47c0;
  }
  .form-check-input {
    height: 1.4em;
  }
}

@media (max-width: 991px) {
  .status {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .status {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .status__left {
    margin-bottom: 20px;
  }
}

@media (max-width: 374px) {
  .status__right {
    flex-direction: column;
  }
  .status__right .form-switch {
    margin-top: 15px;
    margin-left: 0;
  }
}

.exp-content {
  display: flex;
}
.exp-content__sidebar {
  width: 290px;
  margin-right: 20px;
}

.exp-content__edit {
  width: calc(100% - 290px);
  padding: 12px;
  background-color: #fff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.sidebar__img {
  display: block;
  width: 230px;
  height: 230px;
  border-radius: 100%;
  overflow: hidden;
  border: 6px solid #ffffff;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  transition: 0.3s all;
  margin-bottom: 24px;
}
.sidebar__img:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.sidebar {
  margin-top: 42px;
}
.sidebar__name {
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  max-width: 230px;
  display: flex;
  justify-content: center;
  a {
    color: #0a243f;
    &:hover {
      text-decoration: underline;
    }
  }
}
.sidebar__nav {
}
.sidebar__nav ul {
  margin: 0;
  padding: 0;
}
.sidebar__nav li {
  list-style: none;
  margin: 0;
  margin-bottom: 8px;
}
.sidebar__nav li span {
  color: #092c9e;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
.sidebar__nav li a.active {
  color: #092c9e;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  svg {
    margin-left: 5px;
  }
}
.sidebar__nav li a.notActive {
  color: #afacb9;
  svg {
    display: none;
  }
}
.sidebar__nav li span.disable-span {
  color: #afacb9;
  svg {
    display: none;
  }
}
.sidebar__nav a {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #afacb9;
}

.sidebar__nav a:hover {
  text-decoration: underline;
}
.sidebar__nav .sidebar__nav__share {
  margin-top: 14px;
}
.sidebar__nav__share .btn {
  font-size: 14px;
  line-height: 20px;
}
.sidebar__nav .sidebar__nav__menu1 {
  padding-bottom: 8px;
  border-bottom: 1px solid #afacb9;
  max-width: 205px;
  margin-bottom: 26px;
}

.sidebar__nav li.active a {
  color: #092c9e;
  display: flex;
  svg {
    margin-left: 8px;
  }
}

.exp-content__top {
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: #f4f7ff;
  padding: 12px 27px;
  border-radius: 10px 10px 0 0;
  background: #0f47c0;
  margin-bottom: 12px;
}
.exp__about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 16px;
  padding-top: 32px;
  background-size: cover;
  margin-bottom: 7px;
  background-position: center;
  background-size: cover;
}
.exp__about__img {
  width: 158px;
  height: 158px;
  border: 6px solid #ffffff;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  margin-bottom: 16px;
}
.btn-primary {
  background-color: #0f47c0;
  border-color: #0f47c0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
.btn-primary:hover {
  background-color: #092c9e;
  border-color: #092c9e;
}

.btn {
  font-size: 16px;
  line-height: 24px;
  border-radius: 20px;
  padding: 0.45rem 1.4rem;
  font-weight: bold;
}

.about__sett {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 22px;
  margin-bottom: 22px;
}
.about__sett__left {
  display: flex;
  align-items: center;
}
.custom-select {
  font-family: inherit;
  position: relative;
}
.select-selected:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}
.about__sett__color {
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  padding: 5px 1px;
  color: #ffffff;
  border-radius: 2px;
  margin-right: 4px;
}

.about__sett__right {
  width: 250px;
}

.select-selected {
  background: #ffffff;
  border: 1px solid #a1a4b1;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #8b90a0;
  padding: 11px 16px;
  padding-right: 40px;
}
.select-selected:after {
  border-color: #a1a4b1 transparent transparent transparent;
  top: 22px;
  right: 23px;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #a1a4b1 transparent;
  top: 16px;
}

.select-selected.select-arrow-active {
  border: 1px solid #2b5cfb;
  box-sizing: border-box;
  /* Shadow/Level1 */

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 20px 20px 0px 0px;
  color: rgba(0, 0, 0, 0.8);
}

.select-items {
  border: 1px solid #2b5cfb;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 4px 4px;
  background: #ffffff;
}

.select-items div {
  font-size: 14px;
  line-height: 24px;
  color: #8b90a0;
}
.select-items div:hover,
.select-items .same-as-selected {
  color: #092c9e;
}
.select-items div:hover {
  background-color: #fff;
}
.select-items div:hover,
.same-as-selected {
  background-color: #f5fafd;
}
.select-items .same-as-selected:hover {
  background-color: #f5fafd;
}

.about__sett__left {
  .select-selected {
    border: 0;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
  }
  .select-selected:after {
    position: initial;
    display: inline-block;
    border: 0;
    border-color: transparent;
    border-bottom: 1.5px solid #000;
    border-right: 1.5px solid #000;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(-5px);
    margin-left: 3px;
  }
}

h3 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0a243f;
}
.exp-content__title {
  padding-left: 25px;
  border-bottom: 1px solid #a1a4b1;
}

.exp-content__wrap {
  padding-left: 25px;
  max-width: 736px;
  padding-top: 16px;
  margin-bottom: 53px;
}
.exp-content__wrap--big {
  max-width: 954px;
}
.expirience-block--education,
.expirience-block--lang {
  margin-bottom: 15px;
}
.expirience-block--education:last-child,
.expirience-block--lang:last-child {
  margin-bottom: 0;
}
.exp-content__row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.exp-content__row:last-child {
  margin-bottom: 0;
}
.exp-content__row__left {
  flex: 0 0 270px;
  padding-top: 5px;
  padding-right: 15px;
}
.label {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
  span {
    color: var(--color-red);
  }
}
.from-to__col3 {
  span {
    color: var(--color-red);
  }
}
.input {
  background: #ffffff;
  border: 1px solid #a1a4b1;
  border-radius: 20px;
  height: 40px;
  padding: 8px 14px;
  width: 100%;
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 24px;
  color: #18171c;
}
.input:focus {
  border: 1px solid #2b5cfb;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  outline: none;
}
.input.input--custom-radius {
  border-radius: 10px;
  margin-bottom: 15px;
}
.input::placeholder {
  color: #afacb9;
}
.input.error {
  border: 2px solid #f2a60d;
  color: #f2a60d;
}
.input.error:focus {
  box-shadow: 0 0 0 0.25rem rgba(#f2a60d, 0.2);
}

.error-text {
  color: #f2a60d;
  font-size: 14px;
  line-height: 20px;
  margin-top: -5px;
  margin-bottom: 10px;
}
.exp-content__row__right {
  flex: 1;
  .btn {
    margin-top: 20px;
    margin-right: 5px;
  }
  .react-select.select-disabled .react-select__indicator {
    background-image: none;
  }
}
.exp-link {
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #a1a4b1;
  display: inline-block;
  margin-bottom: 5px;
  word-wrap: break-word;
}
@media (max-width: 576px) {
  .exp-link {
    font-size: 10px;
  }
}
.form-check__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.form-check--check {
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 59px;
  .form-check-input {
    border: 2px solid #c3cbcd;
    width: 1.4em;
    height: 1.4em;
    margin-top: 0.1em;
  }
  .form-check-label {
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: 5px;
  }
  .form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='%230F47C0'/></svg>");
  }
  .form-check-input:checked {
    background-color: #fff;
    background-size: 120%;
    border: 2px solid var(--color-blue);
  }
}
.form-check--check:last-child {
  margin-right: 0;
}

.textarea {
  margin-bottom: 15px;
  textarea {
    min-height: 100px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #a1a4b1;
    border-radius: 10px;
    padding: 8px 14px;
    display: block;
    max-height: 300px;
    &:focus {
      border: 1px solid #2b5cfb;
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
      outline: none;
    }
    &.error {
      border: 2px solid #f2a60d;
      color: #f2a60d;
    }
    &.error:focus {
      box-shadow: 0 0 0 0.25rem rgb(242 166 13 / 20%);
    }
  }
  .textarea__maxlength {
    font-size: 12px;
    line-height: 18px;
    color: #a1a4b1;
    margin-top: 5px;
  }
  textarea::placeholder {
    color: #afacb9;
  }
}
.textarea--big {
  textarea {
    min-height: 125px;
  }
}
::-webkit-resizer {
  width: 10px;
  height: 10px;
  background-image: url('../../assets/img/about/textarea-resize.svg');
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: left top;
}

.from-to {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;

  .from-to__line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .from-to__col1 {
    flex: 0 0 25px;
  }
  .from-to__col2 {
    flex: 0 0 170px;
    margin-right: 8px;
  }
}

@media (max-width: 1400px) {
  .exp-content__sidebar {
    margin-right: 25px;
  }
}

@media (max-width: 991px) {
  .exp-content__sidebar {
    width: 200px;
  }
  .exp-content__edit {
    width: calc(100% - 200px);
  }
  .sidebar__img {
    width: 200px;
    height: 200px;
  }
  .exp-content__top {
    font-size: 18px;
    line-height: 1.3;
    padding: 12px 15px;
  }
  .about__sett {
    flex-direction: column;
    padding-left: 0px;
  }
  .sidebar__nav a {
    font-size: 14px;
    line-height: 20px;
  }
  .sidebar__name {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 50px;
  }
  .sidebar {
    margin-top: 20px;
  }
  .about__sett__left {
    margin-bottom: 15px;
  }
  .exp-content__row {
    flex-direction: column;
  }
  .exp-content__row__left {
    flex: auto;
    margin-bottom: 10px;
  }
  .exp-content__row__right {
    width: 100%;
  }
  .exp-content__wrap {
    padding-left: 0;
  }
  .exp-content__title {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .exp-content__edit {
    width: 100%;
  }
  .exp-content {
    flex-direction: column;
  }
  .exp-content__sidebar {
    flex: auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }
  .exp-content__edit {
    width: 100%;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sidebar__top {
    margin-right: 0;
    max-width: 150px;
  }
  .sidebar__img {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
  }
  .sidebar__name {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 15px;
  }
  .sidebar__nav .sidebar__nav__menu1 {
    margin-bottom: 15px;
    max-width: initial;
  }
  h3 {
    font-size: 24px;
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .sidebar {
    flex-direction: column;
  }
  .sidebar__top {
    width: 100%;
    max-width: initial;
    margin-bottom: 15px;
  }
  .sidebar__img {
    margin-left: auto;
    margin-right: auto;
  }
  .sidebar__name {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0;
  }
  .sidebar__nav li {
    margin-bottom: 5px;
  }
  .exp-content__row__left {
    margin-bottom: 5px;
  }
  .about__sett__left .select-selected {
    font-size: 16px;
    line-height: 28px;
  }
}

.exp-content__margin {
  margin-top: 94px;
  margin-bottom: 94px;
}
.exp-content__row--phone {
  margin-bottom: 44px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .exp-content__margin {
    margin-top: 25px;
    margin-bottom: 50px;
  }
  .exp-content__row--phone {
    margin-bottom: 30px;
    margin-top: 15px;
  }
}

@media (min-width: 768px) {
  .person__nav__onlymb {
    display: none;
  }
}

.custom-select--about {
  position: relative;
}

.select-items--about div,
.select-selected--about {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}
.select-selected--about {
  background: #ffffff;
  border: 1px solid #a1a4b1;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #8b90a0;
  padding: 11px 16px;
  padding-right: 40px;
}

.select-selected--about:after {
  position: absolute;
  content: '';
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}
.select-selected--about:after {
  border-color: #a1a4b1 transparent transparent transparent;
  top: 21px;
  right: 23px;
}
.select-hide--about {
  display: none;
}

.select-items--about {
  border: 1px solid #2b5cfb;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 15%);
  border-radius: 0px 0px 4px 4px;
  background: #ffffff;
}
.select-selected--about.select-arrow-active {
  border: 1px solid #2b5cfb;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 15%);
  border-radius: 20px 20px 0px 0px;
  color: rgba(0, 0, 0, 0.8);
}
.select-items--about {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-items--about div:hover {
  background-color: #f5fafd;
}

.select-items--about div {
  background-color: #fff;
}
.select-items--about div:hover,
.select-items--about .same-as-selected {
  color: #092c9e;
}

.select-items--about div {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

.select-items--about div {
  font-size: 14px;
  line-height: 24px;
  color: #8b90a0;
}

.exp-modal__addProect__text1 {
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
}
.exp-modal__addProect__text2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.shablon-flex {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}

.shablon-flex__item {
  margin: 0 25px;
  width: calc(100% / 3 - 50px);
  cursor: pointer;
  margin-bottom: 25px;

  input {
    display: none;
  }
  input + .shablon-flex__item__inner {
    border: 1px solid #a1a4b1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    padding-bottom: 25%;
  }
  input:checked + .shablon-flex__item__inner {
    border: 1px solid #0f47c0;
    box-shadow: 0 0 15px rgba(#0f47c0, 0.6);
  }
}

.shablon-flex__item:hover {
  input + .shablon-flex__item__inner {
    border: 1px solid #0f47c0;
    box-shadow: 0 0 15px rgba(#0f47c0, 0.6);
  }
}

@media (max-width: 991px) {
  .shablon-flex__item {
    width: calc(100% / 2 - 50px);
  }
}

@media (max-width: 549px) {
  .shablon-flex__item {
    width: calc(100% / 1 - 50px);
  }
}

.deleteModal__checkboxes {
  padding: 15px;
  background: #f1f6fa;
  margin-bottom: 25px;
  .form-check {
    font-size: 16px;
    margin-bottom: 0.3rem;
  }
}

#form-check-input__another {
  display: none;
  max-width: 700px;
  margin-bottom: 15px;
}
.deleteModal2__wrap {
  padding: 25px;
  background: #f1f6fa;
  margin-bottom: 25px;
}
.deleteModal2__wrap2 {
  max-width: 400px;
}
.expirience-blocks-lang-new {
  display: flex;
  flex-direction: column;
}
.expirience-blocks-new {
  display: flex;
  flex-direction: column;
}
.block-for-remove {
  display: none;
  order: 2;
  .btn {
    margin-top: 0;
  }
}
.not-empty {
  .block-for-remove {
    display: flex;
  }
}

.form-select {
  background-color: #ffffff;
  border: 1px solid #a1a4b1;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 14px;
  line-height: 24px;
  color: #8b90a0;
  padding: 11px 16px;
  padding-right: 40px;
  position: relative;
  background-image: url('../../assets/img/about/select-img.svg');
  background-size: 12px;
  cursor: pointer;
}
.form-select:focus {
  border: 1px solid #2b5cfb;
  outline: none;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.form-select.error {
  border: 1px solid #f2a60d;
}
.form-select.error:focus {
  box-shadow: 0 0 0 0.25rem rgba(#f2a60d, 0.2);
}
.form-select.form-select--mini {
  padding: 7px 16px;
  margin-bottom: 5px;
}
.custom-select--about {
  .react-select {
    .react-select__single-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #8b90a0;
    }
    .react-select__control {
      height: 48px;
    }
  }
}
// .form-select:after{
//     position: absolute;
//     content: "";
//     display: block;
//     top: 14px;
//     right: 10px;
//     width: 0;
//     height: 0;
//     border: 6px solid transparent;
//     border-color: #fff transparent transparent transparent;
//     border-color: #A1A4B1 transparent transparent transparent;
//     top: 21px;
//     right: 23px;
// }
