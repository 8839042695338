.prpb {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.prpb__name {
  font-weight: 400;
  font-size: 24px;
  line-height: 1.5;
  color: #2b5cfb;
  border-bottom: 1px solid #a1a4b1;
  margin-top: 25px;
  margin-bottom: 32px;
}
.prpb__wrap {
  padding-left: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.prpb__body {
  flex: 1;
}

.prpb__footer {
  display: flex;
  border-top: 1px solid #a1a4b1;
  margin-top: 32px;
  padding-top: 32px;
  padding-bottom: 140px;
  flex-wrap: wrap;
  .btn {
    margin-right: 40px;
    margin-bottom: 10px;
  }
  .btn:last-child {
    margin-right: 0;
  }
}

@media (max-width: 450px) {
  .prbp__detail__tags__item {
    justify-content: space-between;
    width: 100%;
    margin-right: 0;
  }
}

.prpb__hr {
  margin-top: 32px;
  margin-bottom: 32px;
  border-top: 1px solid #a1a4b1;
}

.prpb__label {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #494653;
  margin-bottom: 4px;
}
.prpb__input {
  height: 48px;
  max-width: 384px;
  width: 100%;
  font-size: 20px;
  line-height: 32px;
}

.prpb__error__item {
  color: #eb5757;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
  &:before {
    content: '!';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    min-width: 20px;
    background-color: #eb5757;
    border-radius: 100%;
    color: #fff;
    margin-right: 7px;
  }
}

.prpb__category__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.8);
  max-width: 547px;
  margin-bottom: 22px;
  margin-top: 20px;
}
.prpb__btn {
  color: #2d9cdb;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  background-color: transparent;
  padding: 0;
  transition: 0.3s all;
  &:hover {
    text-decoration: underline;
  }
}
.prpb__category__wrap {
  display: none;
  flex-wrap: wrap;
  &.active {
    display: flex;
  }
}

.prpb__category__col {
  padding-left: 5px;
  &:first-child {
    margin-right: 96px;
  }
}

.prpb__category__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
  input {
    display: block;
    z-index: -99;
    opacity: 0;
    position: absolute;
  }
  input + div {
    display: flex;
    color: #505565;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  input + div:before {
    content: '';
    display: block;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 11px;
    border-radius: 0.25em;
    border: 2px solid #a1a4b1;
  }
  input:checked + div:before {
    border: 2px solid #0d6efd;
    background-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  input:focus + div:before {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }
}
#prpb__category__btn {
  margin-top: 10px;
}
#prpb__category__btn.active {
  display: none;
}

@media (max-width: 767px) {
  .prpb__category__col:first-child {
    margin-right: 0;
  }
  .prpb__input {
    font-size: 16px;
    line-height: 24px;
  }
  .prpb__error__item {
    font-size: 13px;
    line-height: 22px;
  }
  .prpb__category__text {
    font-size: 14px;
    line-height: 26px;
  }
  .prpb__footer {
    padding-bottom: 50px;
    justify-content: space-between;
  }
  .prpb__footer .btn {
    margin: 0;
    margin-bottom: 10px;
  }
  .prpb__name {
    font-size: 20px;
    margin-top: 25px;
  }
  .prpb__wrap {
    padding-left: 0;
  }
}

.prpb__breadcrumb {
  padding-top: 5px;
  ul {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }
  li {
    list-style: none;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    align-items: center;
    a,
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #001828;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  li:after {
    content: '';
    border: 5px solid transparent;
    border-top: 5px solid #0a243f;
    transform: rotate(-90deg);
    margin-left: 10px;
    margin-right: 2px;
    position: relative;
    bottom: -1px;
  }
  li:last-child:after {
    display: none;
  }
}
@media (min-width: 1350px) {
  .prpb__breadcrumb {
    margin-left: -32px;
  }
}
.exp-content--prbp {
  margin-top: 20px;
}

// step 2

.prbp__ul {
  padding: 0;
  margin: 0;
  margin-bottom: 6px;
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: flex-start;
    list-style: none;
    margin-bottom: 12px;
    &:before {
      content: '';
      width: 7px;
      min-width: 7px;
      height: 7px;
      margin-top: 7px;
      margin-right: 4px;
      background-color: #c4c4c4;
      border-radius: 7px;
    }
  }
}

.prpb__textarea {
  textarea {
    min-height: 156px;
    width: 100%;
    border: 1px solid #d7d6dc;
    border-radius: 4px;
    padding: 6px 11px;
    resize: vertical;
    &::placeholder {
      color: #79758a;
    }
  }
}
.prpb__textarea__counter {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: #c4c4c4;
  text-align: right;
}

// step 3
.prpb__detail__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 12px;
  max-width: 547px;
}
.prpb__detail__wrap {
  display: flex;
  margin: 0 -4px;
  width: 100%;
  height: 546px;
  max-height: calc(90vh - 90px);
}
.prpb__detail__col {
  width: calc(100% / 3 - 8px);
  margin-right: 4px;
  margin-left: 4px;
  min-width: 300px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.prpb__acordion {
  background: #f1f6fa;
  border-radius: 15px;
  padding: 8px;
}

.prpb__acordion__row {
}

.prpb__acordion__head {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  padding-right: 25px;
  background: #ffffff;
  border-radius: 15px;
  color: #615e6e;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  position: relative;
  margin-bottom: 8px;

  cursor: pointer;
  &:after {
    position: absolute;
    content: '';
    border: 5px solid transparent;
    border-top: 5px solid #0a243f;
    right: 11px;
    top: calc(50% - 3px);
    transition: 0.1s all;
  }

  &.active:after {
    border: 5px solid transparent;
    border-bottom: 5px solid #0a243f;
    top: calc(50% - 8px);
  }
  &.active {
    .prpb__acordion__checkbox {
      border: 2px solid #0d6efd;
      background-color: #0d6efd;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
}
.prpb__acordion__checkbox {
  display: block;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-right: 11px;
  border-radius: 0.25em;
  border: 2px solid #a1a4b1;
}

.prpb__acordion__body {
  padding-left: 39px;
  display: none;
  ul {
    margin: 0;
    padding: 0;
    margin-bottom: 8px;
  }

  li {
    list-style: none;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.61);
    cursor: pointer;
    &.active {
      color: #053ffa;
    }
    &:hover {
      color: #053ffa;
    }
  }
  &.active {
    display: block;
  }
}
.prbp__detail__wich {
  margin: 0;
  background: #f1f6fa;
  border-radius: 15px;
  padding: 33px 8px;
  min-height: 100%;
  li {
    list-style: none;
    font-weight: 700;
    font-size: 17px;
    line-height: 1.3;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 5px;
    cursor: pointer;
  }
  li.active {
    color: #053ffa;
  }
  li:hover {
    color: #053ffa;
  }
}

.prbp__detail__tags {
  background: #f1f6fa;
  border-radius: 15px;
  padding: 9px 8px;
  height: 100%;
}
.prbp__detail__tags__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #262a39;
  margin-bottom: 15px;
}
.prbp__detail__tags__item {
  background: #dbeefb;
  border-radius: 15px;
  padding: 4px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: #053ffa;
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 5px;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;
    transition: 0.3s all;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: scale(1.1);
      background-color: #fff;
      border-radius: 100%;
    }
  }
}

@media (max-width: 767px) {
  .prpb__detail__col {
    min-width: 260px;
  }
  .prpb__acordion__head {
    font-size: 14px;
  }
  .prpb__acordion__body li {
    font-size: 14px;
    line-height: 22px;
  }
  .prbp__detail__wich li {
    font-size: 14px;
  }
  .prbp__detail__tags__item {
    font-size: 12px;
  }
  .prbp__detail__tags__title {
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.prpb__type {
  display: flex;
  margin-top: 22px;
}
.prpb__type__item {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  padding: 30px;
  cursor: pointer;
  transition: 0.1s all;
  max-width: 304px;
  position: relative;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  &:first-child {
    margin-right: 52px;
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  input + .prpb__type__checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #c3cbcd;
  }
  input:checked + .prpb__type__checkbox {
    background-color: #2d9cdb;
    border: 2px solid #2d9cdb;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  input:focus + .prpb__type__checkbox {
    box-shadow: 0 0 0 0.25rem rgb(13, 110, 253, 0.25);
  }
}
.prpb__type__img {
  display: block;
  margin: 0 auto;
  margin-bottom: 15px;
}
.prpb__type__name {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
  color: #000000;
  text-align: center;
  min-height: 72px;
  margin-bottom: 20px;
}
.prpb__type__text {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.56;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 3px;
  text-align: center;
  &:last-child {
    margin-bottom: 0;
  }
}

.prpb__just-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  max-width: 629px;
  margin-bottom: 8px;
}
.prpb__blue-check {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  input + .prpb__blue-check__input {
    width: 20px;
    min-width: 20px;
    height: 20px;
    border: 2px solid #c3cbcd;
    background-color: #fff;
    margin-right: 6px;
    margin-top: 1px;
  }
  input:checked + .prpb__blue-check__input {
    background-color: #2d9cdb;
    border: 2px solid #2d9cdb;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  input:focus + .prpb__blue-check__input {
    box-shadow: 0 0 0 0.25rem rgb(13, 110, 253, 0.25);
  }
  .prpb__blue-check__text {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: #2d9cdb;
  }
  &.prpb__blue-check--dark {
    input:checked + .prpb__blue-check__input {
      background-color: #053ffa;
      border: 2px solid #053ffa;
    }
    .prpb__blue-check__text {
      color: #053ffa;
    }
  }
}

@media (max-width: 1200px) {
  .prpb__type__item:first-child {
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .prpb__type {
    flex-direction: column;
  }
  .prpb__type__name {
    min-height: initial;
  }
  .prpb__type__text {
    font-size: 16px;
  }
  .prpb__type__name {
    font-size: 22px;
  }
  .prpb__type__item {
    width: 304px;
    max-width: 100%;
    padding: 20px;
    margin-bottom: 15px;
  }
  .prpb__type__item:first-child {
    margin-right: 0px;
  }
}
@media (max-width: 767px) {
  .prpb__type {
    align-items: center;
  }
}

.questions-modal {
  max-width: 100%;
  width: 1356px;
  .modal-dialog {
    max-width: 1356px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
  }
  .questions-modal__head {
    padding: 25px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #a1a4b1;
  }
  .questions-modal__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: rgba(0, 0, 0, 0.8);
  }
  .questions-modal__close {
    background-color: transparent;
    border: 0;
  }
  .questions-modal__body {
    min-height: 500px;
    border-bottom: 1px solid #a1a4b1;
    padding: 35px 22px;
  }
  .questions-modal__footer {
    padding: 20px 22px 34px;
  }
  .questions-modal__list {
    margin-top: 25px;
    max-width: 645px;
  }
  .questions-modal__list__item {
    margin-bottom: 15px;
    input {
      height: 48px;
      margin: 0;
    }
  }
  .questions-modal__list__input {
    padding-right: 20px;
    position: relative;
  }
  .questions-modal__list__limit {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    text-align: right;
  }
  .questions-modal__delete {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .questions-modal__footer {
    display: flex;
    justify-content: flex-end;
  }
  .questions-modal__footer__btns {
    display: flex;
    justify-content: space-between;
  }
  .questions-modal__footer__wrap {
    max-width: 263px;
    width: 100%;
    .text {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 17px;
    }
  }
}

@media (max-width: 767px) {
  .questions-modal {
    .questions-modal__head {
      flex-direction: column-reverse;
      align-items: flex-end;
      padding: 15px;
    }
    .questions-modal__title {
      margin-top: 15px;
      font-size: 20px;
      line-height: 1.5;
    }
    .questions-modal__body {
      padding: 25px 15px;
      min-height: 300px;
    }
    .questions-modal__list__item input {
      font-size: 14px;
      &::placeholder {
        font-size: 11px;
      }
    }
    .questions-modal__list__limit {
      font-size: 14px;
    }
    .questions-modal__footer {
      padding: 20px 15px 34px;
    }
  }
}

.choose__lang {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  margin-bottom: 6px;
}
.choose__lang__item {
  background: #dbeefb;
  border-radius: 15px;
  padding: 4px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: #053ffa;
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 5px;
  font-family: 'Mulish';
  margin-right: 12px;
  margin-bottom: 12px;
  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;
    transition: 0.3s all;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
.propose__lang {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 34px;
}
.propose__lang__item {
  background: #e5e5e5;
  border-radius: 15px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Mulish';
  color: #3c64b1;
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 12px;
}
.prpb__add-lang__wrap {
  margin-top: 14px;
}

.prpb__add-lang {
  .hide-lang {
    display: none;
  }
  .prpb__add-lang__wrap {
    display: none;
    max-width: 630px;
    .selectize-control.single .selectize-input {
      height: 48px;
      border-radius: 15px;
      &.dropdown-active {
        border-radius: 15px 15px 0px 0px;
      }
    }
    .selectize-dropdown {
      border-radius: 0px 0px 15px 15px;
    }
    .selectize-input:after {
      content: '';
      display: block;
    }
  }
  &.active {
    .show-lang {
      display: none;
    }
    .hide-lang {
      display: inline-flex;
    }

    .prpb__add-lang__wrap {
      display: block;
    }
  }
}

.performer {
  margin-top: 22px;
  max-width: 630px;

  .selectize-control.single .selectize-input {
    height: 48px;
    border-radius: 15px;
    &.dropdown-active {
      border-radius: 15px 15px 0px 0px;
    }
  }
  .selectize-dropdown {
    border-radius: 0px 0px 15px 15px;
  }
  .selectize-input:after {
    content: '';
    display: block !important;
  }
}
.prpbSelectOption {
  display: flex;
  align-items: flex-start;
  padding: 10px;
}
.prpbSelectImg {
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 11px;
  img {
    display: block;
    width: 100%;
  }
}
.prpbSelectName {
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 2px;
}
.prpbSelectDes {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 767px) {
  .prpbSelectDes {
    font-size: 12px;
    line-height: 20px;
  }
}

.prpb__radios {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 26px;
}
.prpb__radios__item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 54px;
  margin-bottom: 20px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .prpb__radios__input {
    height: 20px;
    width: 20px;
    min-width: 20px;
    border: 2px solid #c3cbcd;
    border-radius: 100%;
    margin-right: 8px;
    margin-top: 1px;
  }
  input:checked + .prpb__radios__input {
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      content: '';
      display: block;
      width: 12px;

      height: 12px;
      background-color: #053ffa;
      border-radius: 100%;
    }
  }
  input:focus + .prpb__radios__input {
    box-shadow: 0 0 0 0.1rem rgb(13 110 253 / 25%);
  }
  .prpb__radios__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    a:hover {
      text-decoration: underline;
    }
  }
}
.prpb__budget {
  max-width: 316px;
  position: relative;
  font-family: 'Mulish', sans-serif;
  .input {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    height: 48px;
    border-radius: 15px;
    padding-right: 60px;
  }
  .prpb__budget__currency {
    position: absolute;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #828282;
    top: 12px;
    right: 12px;
  }
}

.prpb__name__preview {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
  .btn {
    margin-left: 15px;
    margin-bottom: 12px;
  }
}

@media (max-width: 991px) {
  .prpb__name__preview {
    flex-direction: column;
    align-items: flex-start;
    .btn {
      margin-left: 0;
      margin-top: 15px;
    }
  }
}
.prpreview__t {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #0a243f;
  margin-bottom: 4px;
  margin-top: 12px;
}
.prpb__prname {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  .img {
    width: 48px;
    height: 48px;
    min-width: 48px;
    border-radius: 100%;
    margin-right: 20px;
    background-size: cover;
    background-position: center;
  }
  .name {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
}
@media (max-width: 767px) {
  .prpb__prname {
    .name {
      font-size: 20px;
    }
  }
}

.prpreview {
  position: relative;
  min-height: 116px;
  padding-top: 12px;
  padding-bottom: 24px;
  padding-right: 95px;
  border-bottom: 1px solid #a1a4b1;
  &:last-child {
    border-bottom: 0;
  }
}

.prpreview__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 8px;
}
.prpreview__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 8px;
}
.prpreview__tags__item {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #505565;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
}
.prpb__des {
  padding-top: 23px;
  padding-bottom: 23px;
  border-bottom: 1px solid #a1a4b1;
}
.prpb__des__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 12px;
}
.prpb__des__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  max-width: 929px;
}

.prpb__detail {
  padding-top: 12px;
  padding-bottom: 22px;
  border-bottom: 1px solid #a1a4b1;
}
.prpb__detail__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 12px;
}
.prpreview__tabs {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
      margin-right: 24px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
      a {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #615e6e;
        &.active {
          color: #053ffa;
        }
      }
    }
  }
}

.prtags {
  display: flex;
  flex-wrap: wrap;
}

.prtags__item {
  background: #dbeefb;
  border-radius: 15px;
  padding: 4px 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  color: #053ffa;
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  margin-right: 5px;

  button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin-left: 10px;
    transition: 0.3s all;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    &:hover {
      transform: scale(1.1);
      background-color: #fff;
      border-radius: 100%;
    }
  }
}
.prtags__mulish {
  .prtags__item {
    font-family: 'Mulish';
  }
}
@media (max-width: 450px) {
  .prtags__item {
    justify-content: space-between;
    width: 100%;
  }
}
.prpb__types {
  padding-top: 12px;
  padding-bottom: 25px;
  border-bottom: 1px solid #a1a4b1;
}
.prpb__type__answ {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.prpb__type__block {
  margin-bottom: 37px;
  &:last-child {
    margin-bottom: 0;
  }
}
.prpb__prlang {
  padding-top: 25px;
  padding-bottom: 45px;
  border-bottom: 1px solid #a1a4b1;
}

.prpb__invite {
  padding-top: 15px;
  padding-bottom: 29px;
  border-bottom: 1px solid #a1a4b1;
}
.prpb__invite__item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  .img {
    width: 28px;
    height: 28px;
    min-width: 28px;
    margin-right: 7px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
  }
  .name {
    font-weight: 700;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
  }
  .des {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
  }
}

.prpb__prate {
  padding-top: 12px;
  padding-bottom: 25px;
  border-bottom: 1px solid #a1a4b1;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}
.prpb__pbudget {
  padding-top: 25px;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

@media (max-width: 767px) {
  .prpb__pbudget {
    padding-bottom: 25px;
  }
}
.editbtn {
  position: absolute;
  top: 14px;
  right: 29px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  &:hover {
    background-color: #c3cbcd;
  }
}
@media (max-width: 767px) {
  .prpreview {
    padding-right: 0;
    padding-bottom: 70px;
  }
  .editbtn {
    top: auto;
    bottom: 5px;
    right: 0;
  }
  .prpreview__t {
    font-size: 20px;
    line-height: 30px;
  }
  .prpb__type__answ {
    font-size: 16px;
    line-height: 24px;
  }
}

.custom-modal__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #053ffa;
  text-align: center;
  max-width: 1100px;
  margin: 0 auto;
}

.custom-modal__title--public {
  padding-top: 180px;
  margin-bottom: 47px;
}
.custom-modal__subt {
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  a {
    text-decoration: underline;
    display: inline-block;
  }
}

.custom-modal__subt--public {
  margin-bottom: 69px;
}

.custom-modal__btns {
  display: flex;
  justify-content: center;
  .btn {
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 15px;
    padding: 8px 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  .btn.btn-primary {
    background-color: #092c9e;
  }
}
@media (max-width: 991px) {
  .custom-modal__title--public {
    padding-top: 50px;
  }
  .custom-modal__btns {
    padding-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .custom-modal__btns {
    flex-wrap: wrap;
    justify-content: center;
    .btn {
      margin-right: 5px;
      margin: 0;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .custom-modal__title {
    padding-top: 50px;
    font-size: 24px;
    line-height: 32px;
  }
  .custom-modal__title--public {
    margin-bottom: 25px;
  }
  .custom-modal__subt {
    line-height: 30px;
  }
  .custom-modal__subt--public {
    margin-bottom: 40px;
  }
}

.prpb__acordion__mob-level3 {
  display: none;
}
.prpb__acordion__body {
  .prpb__acordion__mob-level3 {
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    li {
      font-size: 14px;
      line-height: 18px;
      @media (max-width: 768px) {
        &.font__size__13px {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .prpb__acordion__body {
    li.active {
      .prpb__acordion__mob-level3 {
        display: block;
      }
    }
  }

  .prpb__detail__col--second {
    display: none;
  }
  .prpb__detail__col {
    width: calc(50% - 8px);
  }
}
@media (max-width: 991px) {
  .prpb__detail__wrap {
    flex-direction: column;
    height: auto;
    max-height: initial;
    margin: 0;
  }
  .prpb__detail__col {
    width: 100%;
    margin-bottom: 15px;
  }
  .prpb__acordion__body {
    padding-left: 10px;
  }
  .prpb__acordion__body ul li:hover {
    color: rgba(0, 0, 0, 0.61);
  }
  .prpb__acordion__body ul li.active {
    color: #053ffa;
  }
}
