.chatList {
  min-height: 400px;
}
.chatList__item {
  padding-top: 35px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #a1a4b1;
  &:last-child {
    border-bottom: 0;
  }
}

.chatList__head {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.chatList__avatar {
  display: block;
  height: 48px;
  width: 48px;
  min-width: 48px;
  margin-right: 23px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}
.chatList__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
}
.chatList__body {
  padding-left: 71px;
}
.chatList__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #373f41;
  margin-bottom: 24px;
}
.chatList__btn .btn {
  padding: 6px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.chatDetail {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.chatDetail__body {
  height: calc(100% - 20px);
  overflow-y: auto;
  width: 100%;
  padding: 15px;
}
.chatDetail__bot {
  border-top: 1px solid #a1a4b1;
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  background-color: #fff;

  padding-bottom: 10px;
  textarea,
  #muliti-input {
    resize: none;
    min-height: 45px;
    width: 100%;
    padding: 13px;
    padding-bottom: 5px;
    padding-left: 40px;
    padding-right: 35px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #a1a4b1;
  }
}
.chatDetail__bot__wrap {
  display: flex;
  position: relative;
}
.exp-content--chat {
  align-items: flex-start;
  .exp-content__edit {
    display: flex;
    flex-direction: column;
  }
}
.proekt__content__app--mess {
  height: 80vh;
}

.chatDetail__send {
  height: 45px;
  width: 45px;
  background-color: transparent;
  border: 1px solid #a1a4b1;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 0 0 5px;
  &:hover,
  &:focus {
    background-color: #f1f1f1;
    border: 1px solid #000;
  }
}
.chatDetail__file {
  position: absolute;
  top: 5px;
  left: 45px;
  width: 35px;
  height: 35px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;
  z-index: 2;
  border-radius: 100%;
  &:hover,
  &:focus {
    box-shadow: 0 0 5px rgba($color: #000, $alpha: 0.3);
  }
}
.chatDetail__body__item {
  max-width: 50%;
  width: max-content;
  display: flex;
  margin-bottom: 25px;
}
.chatDetail__avatar {
  width: 38px;
  height: 38px;
  min-width: 38px;
  margin-right: 15px;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
}
.chatDetail__mess {
  width: calc(100% - 53px);
}
.chatDetail__mess__text {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  word-break: break-all;
}
.chatDetail__mess__data {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.chatDetail__body__item--my {
  margin-left: auto;
  .chatDetail__mess__text {
    background: #0f47c0;
    color: #fff;
  }
}
.chatDetail__bot__file {
  display: flex;
  align-items: center;

  margin-top: 10px;
  img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
  .text {
    font-weight: bold;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  button {
    margin-left: 10px;
    background-color: transparent;
    border: 0;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      box-shadow: 0 0 5px rgba($color: #000, $alpha: 0.3);
      border-radius: 100%;
    }
  }
}
.chatDetail__mess__file {
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #000;
  background-color: transparent;
  border: 0;
  .text {
    font-weight: bold;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }
  img {
    width: 45px;
    border: 1px solid #747474;
    padding: 5px;
    margin-right: 10px;
    border-radius: 5px;
  }
}
.chatList__unread {
  width: 30px;
  height: 30px;
  background-color: #092c9e;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  font-weight: bold;
  transform: translate(50%, -25%);
}
@media (max-width: 1200px) {
  .chatDetail__body__item {
    max-width: 80%;
  }
}

@media (max-width: 767px) {
  .chatList__item {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .chatList__body {
    padding-left: 0;
  }
  .chatList__btn {
    display: flex;
    justify-content: flex-end;
  }
  .chatList__name {
    font-size: 16px;
    line-height: 24px;
  }
  .chatList__avatar {
    margin-right: 15px;
  }
  .chatDetail__body__item {
    max-width: 100%;
  }
  .chatDetail__body {
    padding: 0;
  }
}
.chatList__empty {
  min-height: 400px;
  padding-top: 15px;
}
.chatList__more {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
}
