.admin-filter {
  display: flex;
  justify-content: flex-end;
  .react-datepicker-wrapper {
    margin-right: 15px;
  }
}
.admin-calendar {
  display: flex;
  position: relative;
}
.admin-calendar__clear {
  background-color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  border: 0;
  transition: 0.1s all;
  &:hover {
    transform: scale(1.1) translateY(-50%);
  }
}
