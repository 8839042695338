.ban__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #053ffa;
  padding-top: 178px;
  margin-bottom: 47px;
}
.ban__text {
  font-weight: 700;
  font-size: 16px;
  line-height: 36px;
  color: #000000;
  text-align: center;
  margin-bottom: 69px;
  a {
    text-decoration: underline;
  }
}
.ban__btn {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .ban__title {
    padding-top: 50px;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
  }
  .ban__text {
    margin-bottom: 25px;
  }
}
