.contact__checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
  input {
    position: absolute;
    z-index: -99;
    opacity: 0;
  }
  input + .contact__checkbox__input {
    border: 3px solid #a1a4b1;
    border-radius: 3px;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 17px;
    svg {
      position: absolute;
      top: -2px;
      left: 0px;
      opacity: 0;
    }
  }
  input:checked + .contact__checkbox__input {
    border: 3px solid #18171c;
    svg {
      opacity: 1;
    }
  }
  .contact__checkbox__right {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0a243f;
  }
  .contact__checkbox__img {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d0dbff;
    border-radius: 20px;
    width: 48px;
    height: 48px;
    margin-right: 12px;
  }
}

.mr-8px {
  margin-right: 8px;
}

.ml-3px {
  margin-left: 3px;
}