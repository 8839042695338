.current-response__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.8);
  margin: 0 -16px;
  padding: 35px 23px 6px;
  border-bottom: 1px solid #a1a4b1;
}
.current-response__data {
  display: flex;
  align-items: center;
  margin: 0 -16px;
  padding: 35px 23px 41px;
  border-bottom: 1px solid #a1a4b1;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.8);
  span {
    color: #0f47c0;
    margin-left: 80px;
  }
}
.current-response__bot {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 60px;
  button {
    margin: 0 12px;
  }
}
.current-response__rate {
  display: flex;
  align-items: center;
  padding-top: 36px;
  padding-bottom: 21px;
  padding-right: 23px;
  padding-left: 23px;
  margin: 0 -16px;
}
.current-response__rate__price {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0f47c0;
  margin-left: 28px;
}
@media (max-width: 575px) {
  .current-response__bot {
    flex-direction: column;
    button {
      margin-bottom: 15px;
    }
  }
}
.current-response__text {
  display: flex;
  align-items: flex-end;
  margin: 0 -16px;
  padding: 0 23px;
  .textarea {
    width: 630px;
    max-width: 100%;
    margin-right: 45px;
    margin-bottom: 0;
    textarea {
      width: 100%;
      border-radius: 4px;
      margin-bottom: 0;
      height: 120px;
      max-height: 200px;
    }
  }
}

@media (max-width: 767px) {
  .current-response__data {
    flex-direction: column;
    span {
      margin-left: 0;
    }
  }
  .current-response__title {
    text-align: center;
    padding-top: 40px;
    padding-left: 15px;
    padding-left: 15px;
  }
  .current-response__data {
    padding: 25px 15px 25px;
    text-align: center;
    span {
      margin-top: 15px;
    }
  }
  .current-response__rate {
    padding: 25px 15px 25px;
    flex-direction: column;
  }
  .current-response__rate__price {
    margin-left: 0;
    margin-top: 15px;
  }
  .current-response__text {
    flex-direction: column;
    padding: 0 15px;
    .textarea {
      margin-right: 0;

      max-width: 100%;
    }
  }
  .current-response__bot {
    margin-top: 25px;
  }
}
.dv-star-rating-star {
  margin-right: 5px;
}
.dv-star-rating-star .active {
  display: none;
}
.dv-star-rating-star.dv-star-rating-full-star {
  .not-active {
    display: none;
  }
  .active {
    display: block;
  }
}
