.delete-modal__pinT {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #022964;
  text-align: center;
  margin-bottom: 43px;
}
.pincode--delete {
  input {
    background-color: transparent;
  }
}
.delete-modal__timer {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #1d83e2;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.auth-bot-btns--delete {
  padding-bottom: 50px;
}

.delete-success {
  position: relative;
  min-height: 680px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1356px;
  max-width: 100%;
  .spec-modal__bottom {
    margin-bottom: 69px;
  }
}
.delete-success__close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.delete-succeess__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #053ffa;
  margin-bottom: 47px;
  text-align: center;
}

@media (max-width: 767px) {
  .delete-modal__timer {
    font-size: 14px;
    line-height: 18px;
  }
  .delete-modal__pinT {
    font-size: 20px;
    line-height: 32px;
  }
  .delete-success {
    min-height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.deleteErr {
  color: red;
  font-weight: bold;
  margin-bottom: 15px;
}
// .spec-modal__bottom--delete {
//   max-width: 800px;
//   margin-right: auto;
//   margin-left: auto;
// }
