.not-auth__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #053ffa;
  padding-top: 218px;
  margin-bottom: 72px;
}

.not-auth__btn {
  display: flex;
  justify-content: center;
  padding-bottom: 99px;
  flex-wrap: wrap;
  .btn {
    margin: 0 5px 15px;
  }
}
.not-auth__bot {
  text-align: center;
  a:hover {
    text-decoration: underline;
  }
}
@media (max-width: 1499px) and (min-width: 992px) {
  .custom-modal-big--not-auth {
    min-height: 500px;
    width: 1200px;
  }
  .not-auth__title {
    padding-top: 120px;
  }
}
@media (max-width: 991px) {
  .not-auth__title {
    padding-top: 50px;
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 32px;
  }
  .not-auth__btn {
    padding-bottom: 50px;
  }
  .not-auth__bot {
    padding-bottom: 50px;
  }
}
