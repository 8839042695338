.js-selectize--mini-input {
  .selectize-control.single .selectize-input {
    background-image: none !important;
    padding-left: 15px;
    height: 40px;
    border: 1px solid #a1a4b1;
    box-shadow: none;
  }
}

.exp-modal__file {
  width: 420px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
  cursor: pointer;
  padding: 20px;
  padding-top: 10px;
  .text {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #001828;
  }
  svg {
    max-width: 100%;
  }
  .file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .text-edit {
    display: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #18a0fb;
    margin-left: 45px;
  }
  .text2 {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    max-width: 325px;
    margin: 0 auto;
    span {
      font-weight: bold;
      margin: 15px 0;
      display: block;
    }
  }
  .img {
    display: none;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .upload-input {
    display: none;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.exp-modal__file {
  border: 1px solid transparent;
  border-radius: 20px;
  transition: 0.1s all;
}
.exp-modal__file:hover {
  cursor: pointer;

  box-shadow: 0 0 10px rgba(#0f47c0, 0.5);
}

.exp-modal-pic .exp-modal__addProect {
  padding-top: 30px;
}

.exp-modal__file.upload {
  svg {
    display: none;
  }
  display: block;
  .img {
    display: block;
  }
  .upload-input {
    display: block;
  }
  .text-edit {
    display: block;
  }
  .text2 {
    display: none;
  }
}
#yourId {
  width: 200px;
  height: 150px;
  position: relative; /* or fixed or absolute */
}

.exp-modal__file .upload-input {
  max-height: 350px;
  max-width: 100%;
}

.croppie-container .cr-slider-wrap {
  position: relative;
  z-index: 5;
}
.croppie-container .cr-boundary {
  max-width: 100%;
}
.cr-slider {
  cursor: grab;
}
.yellow-error {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #f2a60d;
}
.formpic__wrap {
  max-width: 764px;
}
.formpic__wrap__skills {
  background: #ffffff;
  border: 1px solid #d7d6dc;
  border-radius: 20px;
  padding: 16px;
  display: flex;
  margin-top: 15px;
}
input.yellow-error-input {
  border: 2px solid #f2a60d;
}

input.yellow-error-input::placeholder {
  color: #f2a60d;
}

@media (max-width: 767px) {
  .exp-modal-footer {
    .upload-wrap__btns {
      justify-content: flex-start;
    }
  }
}
.period {
  display: flex;
  flex-wrap: wrap;
  .period-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .custom-select {
      width: 150px;
      margin-right: 15px;
      margin-left: 10px;
      font-weight: 500;
    }
    .form-select,
    .react-select {
      width: 150px;
      margin-right: 15px;
      margin-left: 10px;
      font-weight: 500;
    }
  }
}
.period--langs {
  .period-wrap {
    .custom-select,
    .form-select,
    .react-select {
      margin-left: 0;
    }
  }
}
.expirience-blocks-new {
  .expirience-block {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.expirience-blocks-lang-new {
  .expirience-block {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.exp-modal__addProect.exp-modal__addProect--about {
  padding-bottom: 0px;
}

.top-search {
  display: flex;
  flex-wrap: wrap;
  .prpb__breadcrumb {
    margin-left: 0;
  }
  .proekt__search {
    max-width: 100%;
  }
}
.top-search__icon {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  z-index: 2;
}
@media (min-width: 1450px) {
  .top-search {
    margin-left: -32px;
  }
}
.top-search__left {
  flex: 0 0 300px;
  margin-right: 73px;
}

.top-search__right {
  flex: 1;
}

.proekt__search__wrap {
  display: flex;
  flex-wrap: wrap;
  .add-skills__tags {
    width: 100%;
    order: 2;
  }
  .selectize-search {
    flex: 1;
    position: relative;
    .selectize-control {
      height: 48px;
    }
    .selectize-control.single .selectize-input {
      height: 48px;
      padding-left: 41px;
      border-radius: 20px;
      &.dropdown-active {
        border-radius: 20px 20px 0 0;
      }
    }
  }
}
.top-search__btn {
  width: 269px;
  margin-left: 10px;
  height: 48px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
@media (max-width: 1300px) {
  .top-search__left {
    margin-right: 25px;
  }
  .top-search__btn {
    width: 200px;
  }
}
@media (max-width: 991px) {
  .top-search {
    flex-direction: column;
  }
  .top-search__left {
    flex: auto;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .top-search__right {
    flex: auto;
  }
}
@media (max-width: 767px) {
  .top-search__btn {
    font-size: 16px;
    width: 150px;
  }
  .proekt__search {
    margin-bottom: 0;
  }
}
@media (max-width: 500px) {
  .proekt__search__wrap {
    flex-direction: column;
  }
  .selectize-search {
    order: 1;
  }
  .top-search__btn {
    margin-top: 25px;
    width: 100%;
    order: 3;
    margin-left: 0;
    height: 45px;
    line-height: 1;
  }
}

.proekt__right__newTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 14px;
  margin-top: 18px;
}
.proekt__search {
  margin-bottom: 27px;
}
.proekt {
  margin-top: 0;
}
.proekt__right {
  margin-top: 38px;
}

.filter {
  .custom-select--another-border .select-selected {
    border-radius: 20px;
    &.select-arrow-active {
      border-radius: 20px 20px 0 0;
    }
  }
}

.proekt__list__new {
  padding-left: 83px;
  min-height: 500px;
  padding-right: 12px;
  padding-bottom: 15px;
  .proekt__list__item {
    padding-bottom: 12px;
    padding-top: 30px;
  }
  .proekt__content__person {
    position: relative;
  }
  .proekt__content__tags__item {
    text-align: left;
  }
  .proekt__location {
    margin-bottom: 10px;
    svg {
      color: #0f47c0;
    }
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;

    color: #4a4a4a;
  }
  .proekt__content__tags {
    max-width: initial;
  }
  .proekt__content__text {
    max-width: initial;
  }
  .proekt__more {
    padding-bottom: 40px;
  }
  .proekt__more .btn {
    padding: 8px 37px;
  }
}
.proekt__rating {
  color: #0a243f;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  svg {
    margin-right: 12px;
  }
}
.proekt__rating__count {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
  margin-top: 2px;
}
.proekt__content__bot__new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    font-size: 14px;
  }
  .proekt__chat {
    margin-bottom: 0;
    margin-right: 29px;
    display: flex;
    align-items: center;
    font-family: 'Mulish';
    background-color: transparent;
    border: 0;
  }
}
.proekt__pagination {
  padding-bottom: 45px;
  ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    .previous,
    .next {
      display: none;
    }
    li {
      list-style: none;
      margin: 5px 8px;
    }
    a {
      padding: 8px 16px;
      background: #dbeefb;
      border-radius: 6px;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: #3c64b1 !important;
      display: block;
      &:hover {
        background: #a1d1f2;
      }
    }
    li.active {
      a {
        background: #a1d1f2;
      }
    }
  }
}
@media (min-width: 1350px) {
  .proekt__list__new {
    .proekt__content__avatar {
      position: absolute;
      left: -8px;
      transform: translateX(-100%);
      top: 0;
    }
  }
}

@media (max-width: 1350px) {
  .proekt__list__new {
    padding-left: 12px;
  }
}
@media (max-width: 767px) {
  .proekt__list__new {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 575px) {
  .proekt__actios {
    margin-bottom: 15px;
  }
}
.form-check-input[type='radio'].form-check-input--checkbox {
  border-radius: 0;
}
.form-check-input:checked[type='radio'].form-check-input--checkbox {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e');
}
.pdfVeiw {
  width: 913px;
  max-width: 100%;
  height: 536px;
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
  }
  .pdfVeiw__link {
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    width: max-content;
  }
}
.pdfVeiw__link a {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .pdfVeiw {
    height: 300px;
  }
}
.exp-d__level1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #092c9e;
  margin-bottom: 12px;
}
.exp-d__level2 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0a243f;
  margin-bottom: 12px;
}
.exp-d__level3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #092c9e;
  padding: 4px 14px;
  background: #eaf6ff;
  border-radius: 15px;
  margin-bottom: 18px;
  max-width: 747px;
}
.exp-d__bsflex {
  display: flex;
  flex-wrap: wrap;
  .item {
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #092c9e;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px 16px;
    background: #eaf6ff;
    border-radius: 15px;
  }
}
.custom-modal-big {
  width: 1324px;
  position: relative;
  max-width: 100%;
}
.custom-modal-big__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #053ffa;
  padding-top: 115px;
  margin-bottom: 200px;
}
.custom-modal-big__btns {
  display: flex;
  justify-content: center;
  button {
    margin: 0 15px;
  }
}
@media (min-width: 992px) {
  .custom-modal-big {
    min-height: 680px;
  }
}

.custom-modal__close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: 0;
  padding: 0;
}
.btn--new {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  padding: 7px 20px;
}
.btn--new.btn-primary {
  background: #092c9e;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags__item {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #092c9e;
  font-family: 'Mulish';
  margin-right: 8px;
  margin-bottom: 10px;
  padding: 8px 16px;
  background: #eaf6ff;
  border-radius: 15px;
}
.exp-profile--company {
  .exp-profile__tabs__content {
    padding-right: 45px;
    padding-left: 45px;
  }
  .exp-profile__row {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .custom-modal-big {
    width: 342px;
  }
  .custom-modal__close {
    top: 0;
    right: 0;
  }
}
@media (max-width: 767px) {
  .exp-profile--company {
    .exp-profile__tabs__content {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .exp-profile__reviews__top {
    flex-direction: column;
  }
  .exp-profile__reviews__price {
    margin-left: 0;
    margin-top: 15px;
    text-align: left;
  }
}
.bold {
  font-weight: bold;
}
.company__project-list {
  .proekt__actios {
    justify-content: flex-end;
  }
  .proekt__list__item {
    padding-top: 0;
    border: 0;
    border-bottom: 1px solid #e0e4ef;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:last-child {
      border-bottom: 0;
    }
  }
  .proekt__content__person__name {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    margin-bottom: 7px;
    &:hover {
      text-decoration: underline;
    }
  }
  .proekt__location {
    margin-bottom: 10px;
    svg {
      color: #0f47c0;
    }
  }
  .proekt__content__text {
    margin-bottom: 32px;
  }
  .proekt__tags {
    margin-bottom: 0;
  }
  .proekt__tags__item {
    margin-bottom: 10px;
  }
  .proekt__content__person {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .company__project-list {
    .proekt__list__item {
      padding-bottom: 25px;
    }
  }
}
@media (max-width: 575px) {
  .company__project-list {
    .proekt__actios {
      justify-content: flex-start;
    }
  }
}
.sidebar__mobSelect {
  display: flex;
  justify-content: center;
  width: 100%;
  select {
    max-width: 272px;
    width: 100%;
    padding: 9px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #8b90a0;
  }
}
.alert.fix {
  position: fixed;
  top: 0;
  z-index: 5;
  right: 15px;
  max-width: calc(100% - 30px);
}
@media (min-width: 1200px) {
  .my-offers--projectinfo {
    padding-left: 60px;
  }
}
.back-url {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: #2b5cfb;
  margin-bottom: 25px;
  svg {
    margin-right: 15px;
    transform: rotate(180deg);
  }
}
.actionModal {
  background: #f4f7ff;
  position: absolute;
  padding: 48px 15px 37px;
  width: 462px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transform: translateX(-100%);
  z-index: 2;
  max-width: calc(100% - 60px);
  .actionModal__close {
    position: absolute;
    top: 4px;
    right: 6px;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    padding: 0;
    &:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }
  }
  .actionModal__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #022964;
    text-align: center;
    margin-bottom: 23px;
  }
}
.actionModal.mob {
  transform: translate(0);
}
.not-auth__title--not-active {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}

.react-tooltip {
  max-width: 400px;
}
@media (max-width: 420px) {
  .react-tooltip {
    max-width: 100%;
  }
}
.support-btn-link {
  background-color: transparent;
  border: 0;
  color: #0d6efd;
  display: inline-block;
  font-weight: bold;
  text-decoration: underline;
}
