.alerts__item {
  border-bottom: 1px solid #a1a4b1;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 35px;
  &:last-child {
    border-bottom: 1px solid #e0e4ef;
  }
}
.alerts__top {
  display: flex;
  align-items: flex-start;

  margin-bottom: 10px;
}
.alerts__img {
  width: 48px;
  min-width: 48px;
  height: 48px;
  margin-right: 23px;
  border-radius: 100%;
  background-size: contain;
  background-position: center;
}
.alerts__name {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0a243f;
}
// .alerts__body {
//   padding-left: 71px;
// }
.alerts__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #373f41;
  margin-bottom: 24px;
}
.alerts__btns {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .btn {
    margin-right: 24px;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 20px;
    margin-bottom: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 767px) {
  .alerts__img {
    margin-right: 15px;
  }
  .alerts__body {
    padding-left: 0;
  }
  .alerts__btns {
    .btn {
      padding: 5px 10px;
      margin-right: 15px;
    }
  }
  .alerts__item {
    padding-right: 0;
    padding-left: 0;
  }
}
